<template>
  <div>

    <!--    Ekle Modal-->
    <b-modal
      id="new_node_append_modal"
      ref="new_node_append_modal"
      centered
      no-close-on-backdrop
      content-class="shadow"
      :title="$t('core.model_title_add')"
      footer-class="d-none"
      size="md"
      @hide="close_new_node_append_modal"
    >
      <div>
        <!-- search input -->
        <b-input-group class="mb-1">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model.lazy="searchword"
            placeholder="Search..."
            @input="search"
          />
        </b-input-group>

        <!-- tree -->
        <v-tree
          ref="tree"
          :data="options"
        />
        <hr>
        <b-row class="justify-content-end">
          <b-button
            size="sm"
            variant="primary"
            class="mr-1"
            @click="new_record_add_button"
          >

            <span class="align-middle">{{ $t('core.btn_add') }}</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Ekle Modal-->

    <!--    Sil Modal-->
    <b-modal
      id="delete_record_modal"
      ref="delete_record_modal"
      class="danger"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Gruptan Çıkar"
      footer-class="d-none"
      size="lg"
      @hide="close_delete_record_modal"
    >
      <div>
        <b-card-text class="text-left">
          <p><span class="text-danger">{{ selected_row_name }}</span> isimli birimi gruptan çıkarmak istediğinize emin misiniz?</p>
          <p>Gruptan çıkarma sonrası kayıt için tekrardan QR kod kurulumu yapmalısınız.</p>
        </b-card-text>
        <b-row class="justify-content-end">
          <b-button
            size="sm"
            variant="primary"
            class="mr-1"
            @click="close_delete_record_modal()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-1"
            />
            <span class="align-middle">İptal</span>
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            class="mr-1"
            @click="record_delete_button()"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-1"
            />
            <span class="align-middle">Gruptan Çıkar</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Sil Modal-->

    <div class="mb-1">
      <!-- Table Top -->
      <b-row class="justify-content-end">
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              size="sm"
              class="d-inline-block mr-1"
              :placeholder="$t('core.search')"
            />
            <b-button
              variant="primary"
              size="sm"
              @click="addModal()"
            >
              <span class="text-nowrap">{{ $t('core.btn_new_add') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      responsive
      :items="table_data"
      :fields="tableColumns"
      primary-key="id"
      style="min-height: 500px"
      show-empty
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      :empty-text="$t('core.no_records_found')"
      small
      striped
    >
      <template #cell(node)="data">
        {{ data.item.node.name }}
      </template>
      <!-- Column: Actions -->
      <template #cell(id)="data">
        <div class="text-nowrap">
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="rowDeleteModal(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Gruptan Çıkar</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
import {
  BCard, BButton, BRow, BCol,
  BModal,
  BLink,
  BFormInput,
  BTable,
  BCardText,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BPagination, VBModal, BTabs, BTab, BInputGroupPrepend, BInputGroup,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { getUserData } from '@/auth/utils'
import { VTree } from 'vue-tree-halower'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    VTree,
    BCard,
    BButton,
    BRow,
    BCol,
    BModal,
    BFormInput,
    BTable,
    BCardText,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    selected_row_data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected_row_name: null,
      selected_row_id: null,
      detail_modal_show: false,
      perPageOptions: [25, 50, 100],
      searchQuery: null,
      sortBy: null,
      isSortDirDesc: null,
      table_data: null,
      tableColumns: [
        { key: 'node', label: 'Birim Adı', sortable: true },
        { key: 'id', label: '#' },
      ],
      currentPage: 1,
      totalRows: 0,
      perPage: 5000,
      name: null,
      edit_group_name: null,
      organization_id: router.currentRoute.params.id,
      searchword: '',
      options: [],
    }
  },
  computed: {
  },
  watch: {
    sortBy: { handler() { this.getTableData() } },
    isSortDirDesc: { handler() { this.getTableData() } },
    currentPage: {
      handler() {
        this.getTableData()
      },
    },
    searchQuery: {
      handler() {
        this.getTableData()
      },
    },
  },
  created() {
    this.getTableData()
  },
  setup() {},
  methods: {
    search() {
      this.$refs.tree.searchNodes(this.searchword)
    },
    restructureArray(data) {
      const dataMap = {}
      const root = []

      data.forEach(item => {
        let check_status = false
        this.table_data.forEach(dd => {
          if (dd.node.id == item.id) {
            check_status = true
          }
        })
        dataMap[item.id] = {
          ...item,
          title: item.name,
          nocheck: check_status,
          children: [],
          expanded: true,
        }
      })

      data.forEach(item => {
        const parentId = dataMap[item.parentId]
        if (parentId) {
          parentId.children.push(dataMap[item.id])
        } else {
          root.push(dataMap[item.id])
        }
      })

      return root
    },
    getOrganizationNodeData() {
      useJwt.getCompanyOrganizationNodesList({
        company: getUserData().company_id,
        organization: this.organization_id,
        page_size: 50000,
      })
        .then(response => {
          const oop = this.restructureArray(response.data.results)
          this.options = oop
        })
        .catch(error => {
          console.log(error)
        })
    },
    getTableData() {
      useJwt.getQRCodeGroupNodesList({
        company: getUserData().company_id,
        organization: this.organization_id,
        group: this.selected_row_data.id,
        page: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
      })
        .then(response => {
          this.table_data = response.data.results
          this.totalRows = response.data.count
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    addModal() {
      this.getOrganizationNodeData()
      this.$refs.new_node_append_modal.show()
    },
    rowDeleteModal(row_id) {
      useJwt.QRCodeGroupNodesDetail(row_id)
        .then(response => {
          this.selected_row_name = response.data.node.name
          this.selected_row_id = response.data.id
          this.$refs.delete_record_modal.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    new_record_add_button() {
      const selected_nodes = this.$refs.tree.getSelectedNodes()
      const node_list = []

      selected_nodes.forEach(item => {
        let check_status = false
        this.table_data.forEach(dd => {
          if (dd.node.id == item.id) {
            check_status = true
          }
        })
        if (check_status == false) {
          node_list.push(item.id)
        }
      })
      const formData = new FormData()
      formData.append('group', this.selected_row_data.id)
      formData.append('company', getUserData().company_id)
      formData.append('organization', this.organization_id)
      formData.append('node_list', node_list)
      // formData.append('node_list', [1,2,3])
      useJwt.QRCodeGroupNodesmultiAddSurveyGroups(formData)
        .then(response => {
          this.getTableData()
          this.close_new_node_append_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Eklendi',
              icon: 'SaveIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertIcon',
              variant: 'danger',
              text: error.response.data.detail,
            },
          })
          console.log(error.response)
        })
    },
    record_delete_button() {
      useJwt.QRCodeGroupNodesDelete(this.selected_row_id)
        .then(response => {
          this.getTableData()
          this.close_delete_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt gruptan çıkarıldı.',
              icon: 'CheckCircleIcon',
              variant: 'danger',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    close_new_node_append_modal() {
      this.$refs.new_node_append_modal.hide()
      this.name = null
    },
    close_delete_record_modal() {
      this.$refs.delete_record_modal.hide()
      this.selected_row_id = null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import "@core/scss/vue/libs/tree.scss";
</style>
