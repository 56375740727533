<template>
  <div>
    <!--    qrcode Group Detail Modal-->
    <b-modal
      id="qrcode_group_detail_modal"
      ref="qrcode_group_detail_modal"
      centered
      no-close-on-backdrop
      content-class="shadow"
      footer-class="d-none"
      :title="selected_row_name"
      size="lg"
      @hide="close_qrcode_group_detail_modal"
    >
      <div>
        <b-tabs class="mb-0">
          <b-tab
            title="QR Kod Tasarımı"
            active
          >
            <qrcode-tab :selected_row_data="selected_row_data" />
          </b-tab>
          <b-tab title="Birim Yönetimi">
            <nodes-tab  :selected_row_data="selected_row_data"/>
          </b-tab>
          <!--          <b-tab title="Kullanıcı Yönetimi">-->
          <!--            ssssda-->
          <!--          </b-tab>-->
        </b-tabs>
      </div>

    </b-modal>
    <!--    qrcode Group Detail Modal-->

    <!--    Ekle Modal-->
    <b-modal
      id="new_record_modal"
      ref="new_record_modal"
      centered

      no-close-on-backdrop
      content-class="shadow"
      :title="$t('core.model_title_add')"
      footer-class="d-none"
      size="md"
      @hide="close_new_record_modal"
    >
      <div>
        <!-- form -->
        <validation-observer
          ref="addForm"
          #default="{invalid}"
        >
          <b-form @submit.prevent="new_record_add_button">
            <b-row>
              <b-col
                cols="12"
                class="mb-md-0 mb-2 required"
              >
                <b-form-group>
                  <label for="name"><span class="text-danger text-bold">*</span> Grup Adı</label>
                  <validation-provider
                    #default="{ errors }"
                    name="group_name"
                    vid="group_name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="name"
                      placeholder="Grup Adı"
                      :state="errors.length > 0 ? false:null"
                      name="group_name"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>

                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mb-md-0 mb-2"
              >
                <b-form-group
                  label="Açıklama"
                  label-for="name"
                >
                  <b-form-input
                    v-model="group_comment"
                    placeholder="Açıklama"
                    name="name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mb-md-0 mb-2"
              >
                <b-form-group>
                  <label for="i-plan"><span class="text-danger text-bold">*</span> Erişim Türü</label>
                  <v-select
                    id="i-plan"
                    v-model="access_type"
                    :options="access_type_options"
                    label="title"
                    :clearable="false"
                  >
                    <template #option="{ title, icon }" />
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row class="justify-content-end">
              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                type="submit"
                :disabled="invalid"
              >
                <span class="align-middle">{{ $t('core.btn_add') }}</span>
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </div>

    </b-modal>
    <!--    Ekle Modal-->

    <!--    Güncelle Modal-->
    <b-modal
      id="update_record_modal"
      ref="update_record_modal"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Kayıt Düzenle"
      footer-class="d-none"
      size="lg"
      @hide="close_edit_record_modal"
    >
      <div>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group>
              <label for="name"><span class="text-danger text-bold">*</span> Grup Adı</label>
              <b-form-input
                v-model="edit_group_name"
                placeholder="Anket Grup Adı"
                name="name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Açıklama"
              label-for="name"
            >
              <b-form-input
                v-model="edit_group_comment"
                placeholder="Açıklama"
                name="name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group>
              <label for="i-plan"><span class="text-danger text-bold">*</span> Erişim Türü</label>
              <v-select
                id="i-plan"
                v-model="edit_access_type"
                :options="access_type_options"
                label="title"
                :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            size="sm"
            variant="primary"
            class="mr-1"
            @click="record_edit_button()"
          >

            <span class="align-middle">Güncelle</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Güncelle Modal-->

    <!--    Sil Modal-->
    <b-modal
      id="delete_record_modal"
      ref="delete_record_modal"
      class="danger"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Kayıt Sil"
      footer-class="d-none"
      size="md"
      @hide="close_delete_record_modal"
    >
      <div>
        <b-card-text class="text-left">
          <span class="text-danger"><b><u>{{ selected_row_name }}</u></b></span><span> isimli kaydı silmek istediğinize emin misiniz?</span>
        </b-card-text>
        <b-row class="justify-content-end">
          <b-button
            size="sm"
            variant="primary"
            class="mr-1"
            @click="close_delete_record_modal()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-1"
            />
            <span class="align-middle">İptal</span>
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            class="mr-1"
            @click="record_delete_button()"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-1"
            />
            <span class="align-middle">Sil</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Sil Modal-->

    <div>
      <!-- Table Top -->
      <b-row class="mb-25">

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >

          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />

        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              size="sm"
              class="d-inline-block mr-1"
              :placeholder="$t('core.search')"
            />
            <b-button
              v-b-modal.new_record_modal
              variant="primary"
              size="sm"
            >
              <span class="text-nowrap">{{ $t('core.btn_new_add') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-table
        size="sm"
        responsive
        :items="table_data"
        :fields="tableColumns"
        primary-key="id"
        style="min-height: 500px"
        show-empty
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :empty-text="$t('core.no_records_found')"
      >
        <template #cell(group_name)="data">
          <b-link
            class="text-primary"
            size="sm"
            @click="rowDetailModal(data.item.id)"
          >
            <u>{{ data.item.group_name }}</u>
          </b-link>
        </template>

        <!-- Column: access_type -->
        <template #cell(access_type)="data">
          <div v-if="data.item.access_type=='private'">
            ALT BİRİMLER
          </div>
          <div v-if="data.item.access_type=='public'">
            BÜTÜN BİRİMLER
          </div>
        </template>
        <!-- Column: access_type -->

        <!-- Column: Actions -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="rowUpdateModal(data.item.id, )">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Düzenle</span>
              </b-dropdown-item>
              <b-dropdown-item @click="rowDeleteModal(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Sil</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>

</template>

<script>
import {
  BCard, BButton, BRow, BCol,
  BModal,
  BLink,
  BFormInput,
  BTable,
  BCardText,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BPagination, VBModal, BTabs, BTab, BForm,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { getUserData } from '@/auth/utils'
import NodesTab from '@/views/custom_app/client/organization_management/components/qrcode_groups/NodesTab.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import QrcodeTab from "@/views/custom_app/client/organization_management/components/qrcode_groups/QrcodeTab.vue"
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    NodesTab,
    BTab,
    BTabs,
    BCard,
    BButton,
    BRow,
    BCol,
    BModal,
    BLink,
    BFormInput,
    BTable,
    BCardText,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    QrcodeTab,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected_row_name: null,
      selected_row_data: null,
      selected_row_id: null,
      detail_modal_show: false,
      perPageOptions: [25, 50, 100],
      searchQuery: null,
      sortBy: null,
      isSortDirDesc: null,
      table_data: null,
      tableColumns: [
        { key: 'group_name', label: 'Grup Adı', sortable: true },
        { key: 'group_comment', label: 'Açıklama', sortable: true },
        { key: 'access_type', label: 'Erişim Türü', sortable: true },
        { key: 'id', label: '#' },
      ],
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      name: null,
      group_comment: '',
      edit_group_comment: '',
      access_type: { title: 'ALT BİRİMLER', value: 'private' },
      edit_access_type: { title: 'ALT BİRİMLER', value: 'private' },
      access_type_options: [
        { title: 'ALT BİRİMLER', value: 'private' },
        { title: 'BÜTÜN BİRİMLER', value: 'public' },
      ],
      edit_group_name: null,
      organization_id: router.currentRoute.params.id,
    }
  },
  computed: {
  },
  watch: {
    sortBy: { handler() { this.getTableData() } },
    isSortDirDesc: { handler() { this.getTableData() } },
    currentPage: {
      handler() {
        this.getTableData()
      },
    },
    searchQuery: {
      handler() {
        this.getTableData()
      },
    },
  },
  created() {
    this.getTableData()
  },
  setup() {},
  methods: {
    getTableData() {
      useJwt.getQRCodeGroupsList({
        company: getUserData().company_id,
        organization: this.organization_id,
        page: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
      })
        .then(response => {
          this.table_data = response.data.results
          this.totalRows = response.data.count
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    rowUpdateModal(row_id) {
      useJwt.QRCodeGroupsDetail(row_id)
        .then(response => {
          this.edit_group_name = response.data.group_name
          this.edit_group_comment = response.data.group_comment
          this.access_type_options.forEach(access_type => {
            if (access_type.value == response.data.access_type) {
              this.edit_access_type = access_type
            }
          })
          this.selected_row_id = response.data.id
          this.$refs.update_record_modal.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    rowDeleteModal(row_id) {
      useJwt.QRCodeGroupsDetail(row_id)
        .then(response => {
          this.selected_row_name = response.data.group_name
          this.selected_row_id = response.data.id
          this.$refs.delete_record_modal.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    rowDetailModal(row_id) {
      useJwt.QRCodeGroupsDetail(row_id)
        .then(response => {
          this.selected_row_name = response.data.group_name
          this.selected_row_data = response.data
          this.selected_row_id = response.data.id
          this.$refs.qrcode_group_detail_modal.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    new_record_add_button() {
      this.$refs.addForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('group_name', this.name)
          formData.append('company', getUserData().company_id)
          formData.append('organization', this.organization_id)
          formData.append('group_comment', this.group_comment)
          formData.append('access_type', this.access_type.value)
          useJwt.createQRCodeGroups(formData)
            .then(response => {
              this.getTableData()
              this.close_new_record_modal()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Kayıt Eklendi',
                  icon: 'SaveIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'AlertIcon',
                  variant: 'danger',
                  text: error.response.data.detail,
                },
              })
              console.log(error.response)
            })
        }
      })
    },
    record_edit_button() {
      const formData = new FormData()
      formData.append('group_name', this.edit_group_name)
      formData.append('group_comment', this.edit_group_comment)
      formData.append('access_type', this.edit_access_type.value)
      useJwt.updateQRCodeGroups(this.selected_row_id, formData)
        .then(response => {
          this.getTableData()
          this.close_edit_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Güncellendi',
              icon: 'CheckCircleIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    record_delete_button() {
      useJwt.QRCodeGroupsDelete(this.selected_row_id)
        .then(response => {
          this.getTableData()
          this.close_delete_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Silindi',
              icon: 'CheckCircleIcon',
              variant: 'danger',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    close_new_record_modal() {
      this.$refs.new_record_modal.hide()
      this.name = null
      this.access_type = { title: 'ALT BİRİMLER', value: 'private' }
      this.group_comment = ''
    },
    close_edit_record_modal() {
      this.$refs.update_record_modal.hide()
      this.selected_row_id = null
    },
    close_delete_record_modal() {
      this.$refs.delete_record_modal.hide()
      this.selected_row_id = null
    },
    close_qrcode_group_detail_modal() {
      this.$refs.qrcode_group_detail_modal.hide()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>
