<template>
  <div>
    <add-new-node-modal
      :organization_node_list="organization_node_list"
      :update_organization_data="update_organization_data"
      :get_user_node_access_list="get_user_node_access_list"
    />

    <detail-node-modal
      :organization_node_list="organization_node_list"
      :customer_is_admin="customer_is_admin"
      :pp="pp"
    />
    <survey-groups-modal />
    <qrcode-groups-modal />
    <move-node-modal :update_organization_data="update_organization_data" />

    <delete-node-modal :update_organization_data="update_organization_data" />

    <edit-node-modal
      :organization_node_list="organization_node_list"
      :update_organization_data="update_organization_data"
    />

    <!-- Top Header -->
    <b-row class="align-items-center">
      <b-col cols="4">
        <b-form-input
          v-model="searchQuery"
          size="sm"
          :placeholder="$t('core.search')"
        />
      </b-col>
      <b-col cols="8">
        <b-button
          v-if="is_nodes_collapse == true"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mr-25"
          size="sm"
          variant="outline-primary"
          @click="is_nodes_collapse=false;chartReference.collapseAll().fit()"
        >
          <feather-icon
            icon="GitCommitIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('organization_renderer.btn_collapse') }}</span>
        </b-button>
        <b-button
          v-if="is_nodes_collapse == false"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mr-25"
          size="sm"
          variant="outline-primary"
          @click="is_nodes_collapse=true;chartReference.expandAll().fit()"
        >
          <feather-icon
            icon="GitMergeIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('organization_renderer.btn_expand') }}</span>
        </b-button>
        <b-button
          v-if="is_nodes_compact == false"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mr-25"
          size="sm"
          variant="outline-primary"
          @click="is_nodes_compact=true;chartReference.compact(false).render().fit()"
        >
          <feather-icon
            icon="CodesandboxIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('organization_renderer.btn_horizontal') }}</span>
        </b-button>
        <b-button
          v-if="is_nodes_compact == true"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mr-25"
          size="sm"
          variant="outline-primary"
          @click="is_nodes_compact=false;chartReference.compact(true).render().fit()"
        >
          <feather-icon
            icon="CodepenIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('organization_renderer.btn_compact') }}</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mr-25"
          variant="outline-primary"
          size="sm"
          @click="chartReference.fullscreen()"
        >
          <feather-icon
            icon="MaximizeIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('organization_renderer.btn_full_screen') }}</span>
        </b-button>
        <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          right
          class="mr-25"
          size="sm"
          variant="outline-primary"
          :text="$t('organization_renderer.btn_scheme')"
        >
          <b-dropdown-item
            @click="chartReference.layout('top').render().fit()"
          >
            <feather-icon
              icon="CornerLeftDownIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('organization_renderer.btn_up') }}</span>
          </b-dropdown-item>
          <b-dropdown-item
            @click="chartReference.layout('bottom').render().fit()"
          >
            <feather-icon
              icon="CornerLeftUpIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('organization_renderer.btn_bottom') }}</span>
          </b-dropdown-item>
          <b-dropdown-item
            @click="chartReference.layout('left').render().fit()"
          >
            <feather-icon
              icon="CornerDownRightIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('organization_renderer.btn_right') }}</span>
          </b-dropdown-item>
          <b-dropdown-item
            @click="chartReference.layout('right').render().fit()"
          >
            <feather-icon
              icon="CornerDownLeftIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('organization_renderer.btn_left') }}</span>
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          right
          class="mr-25"
          size="sm"
          variant="outline-primary"
          :text="$t('organization_renderer.btn_action')"
        >
          <!-- Anket Grubu          -->
          <b-dropdown-item
            v-b-modal.survey_groups_modal
            size="sm"
          >
            <feather-icon
              icon="CheckCircleIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('organization_renderer.btn_survey_group') }}</span>
          </b-dropdown-item>
          <!-- Anket Grubu          -->

          <!-- QR Kod Grubu          -->
          <b-dropdown-item
            v-b-modal.qrcode_groups_modal
            size="sm"
          >
            <i class="pi pi-qrcode mr-50" />
            <span class="align-middle">{{ $t('organization_renderer.btn_qrcode_group') }}</span>
          </b-dropdown-item>
          <!-- QR Kod Grubu          -->

          <!-- OneLink Grubu          -->
          <b-dropdown-item
            v-b-modal.survey_groups_modal
            size="sm"
          >
            <feather-icon
              icon="LinkIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('organization_renderer.btn_biolink_group') }}</span>
          </b-dropdown-item>
          <!-- OneLink Grubu          -->

        </b-dropdown>
      </b-col>
    </b-row>
    <!-- Top Header -->

    <div ref="svgElementContainer" />
  </div>

</template>
<script>
import {
  BButton,
  VBModal,
  BRow,
  BCol,
  BFormInput,
  BDropdownItem, BDropdown,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { OrgChart } from 'd3-org-chart'
import useJwt from '@/auth/jwt/useJwt'
import DetailNodeModal from '@/views/custom_app/client/organization_management/components/detail_node_modal/DetailNodeModal.vue'
import AddNewNodeModal from '@/views/custom_app/client/organization_management/components/AddNewNodeModal.vue'
import MoveNodeModal from '@/views/custom_app/client/organization_management/components/MoveNodeModal.vue'
import EditNodeModal from '@/views/custom_app/client/organization_management/components/EditNodeModal.vue'
import DeleteNodeModal from '@/views/custom_app/client/organization_management/components/DeleteNodeModal.vue'
import SurveyGroupsModal from '@/views/custom_app/client/organization_management/components/survey_groups/SurveyGroupsModal.vue'
import QrcodeGroupsModal
  from '@/views/custom_app/client/organization_management/components/qrcode_groups/QrcodeGroupsModal.vue'

export default {
  components: {
    DetailNodeModal,
    AddNewNodeModal,
    MoveNodeModal,
    EditNodeModal,
    DeleteNodeModal,
    SurveyGroupsModal,
    QrcodeGroupsModal,
    BButton,
    BCol,
    BFormInput,
    BRow,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    organization_data: {
      type: Array,
      required: true,
    },
    child_nodes: {
      type: Array,
      required: true,
    },
    parent_nodes: {
      type: Array,
      required: true,
    },
    pp: {
      type: Array,
      required: true,
    },
    customer_is_admin: {
      type: Boolean,
      required: true,
    },
    update_organization_data: {
      type: Function,
      required: true,
    },
    get_user_node_access_list: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      is_nodes_collapse: false,
      is_nodes_compact: false,
      organization_node_list: [],
      organization_node_list_is_load: false,
      chartReference: null,
      searchQuery: null,
    }
  },
  watch: {
    organization_data(value) {
      this.renderChart(value)
    },
    searchQuery(keyword) {
      const value = keyword

      // Clear previous higlighting
      this.chartReference.clearHighlighting()

      // Get chart nodes
      const data = this.chartReference.data()

      // Mark all previously expanded nodes for collapse
      data.forEach(d => (d._expanded = false))

      // Loop over data and check if input value matches any name
      data.forEach(d => {
        if (value != '' && d.name.toLowerCase().includes(value.toLowerCase())) {
          // If matches, mark node as highlighted
          d._highlighted = true
          d._expanded = true
        }
      })

      // Update data and rerender graph
      this.chartReference.data(data).render().fit()
    },
  },
  created() {
    this.getOrganizationNodes()
  },
  mounted() {
  },
  methods: {
    getOrganizationNodes() {
      useJwt.getOrganizationTypeList()
        .then(response => {
          this.organization_node_list = response.data.organization_type_list
          this.organization_node_list.sort((a, b) => ((a.name > b.name) ? 1 : -1))
          this.organization_node_list_is_load = true
          this.renderChart(this.organization_data)
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    renderChart(data) {
      if (!this.chartReference && this.organization_node_list_is_load == true) {
        this.chartReference = new OrgChart()
      }
      this.chartReference
        .container(this.$refs.svgElementContainer) // node or css selector
        .nodeHeight(d => 85 + 25)
        .nodeWidth(d => 220 + 2)
        .childrenMargin(d => 50)
        .compactMarginBetween(d => 35)
        .compactMarginPair(d => 30)
        .neighbourMargin((a, b) => 20)
        .nodeContent((d, i, arr, state) => {
          console.log(this.customer_is_admin)
          console.log(this.child_nodes)
          const color = d.data.node_color
          let options_menu_show = 'none' // Menü gösterme yetkisi
          let add_btn_show = 'none' // Yeni Birim ekle yetkisi
          let delete_btn_show = 'none' // Birim silme yetkisi
          let edit_btn_show = 'none' // Birim düzenleme yetkisi
          let admin_mode = 'none'

          const lbl_view = this.$t('organization_renderer.btn_review')
          const lbl_edit = this.$t('organization_renderer.btn_edit')
          const lbl_new_node_add = this.$t('organization_renderer.btn_new_node_add')
          const lbl_move = this.$t('organization_renderer.btn_node_move')
          const lbl_delete = this.$t('organization_renderer.btn_node_delete')

          const lbl_title_number_of_qr_code_readings = this.$t('organization_renderer.title_number_of_qr_code_readings')
          const lbl_title_total_number_of_feedback = this.$t('organization_renderer.title_total_number_of_feedback')
          const lbl_title_number_of_open_feedback = this.$t('organization_renderer.title_number_of_open_feedback')

          // Müşteri adminse bütün ekranları görüyor, değilse permission durumuna göre görüyor
          if (this.customer_is_admin == true) {
            options_menu_show = 'block'
            add_btn_show = 'block'
            delete_btn_show = 'block'
            edit_btn_show = 'block'
            admin_mode = 'block'
          } else {
            this.child_nodes.forEach(i => {
              // Node erişim yetkisi varsa menü gösteriliyor yoksa gösterilmiyor
              if (i == d.id || this.customer_is_admin == true) {
                options_menu_show = 'block'

                // Erişim yetkisi olan nodelar için buton yetkisi ayarlanıyor
                this.pp.forEach(p => {
                  p.child_nodes.forEach(child => {
                    if (child == d.id) {
                      if (p.node.node_create == true) {
                        add_btn_show = 'block'
                      }
                      if (p.node.node_write == true) {
                        edit_btn_show = 'block'
                      }
                      if (p.node.node_delete == true) {
                        delete_btn_show = 'block'
                      }
                    }
                  })
                })
              }
            })
          }
          // ana node için taşıma ve silme yetkisi alınıyor
          if (d.data.node_type_code == 'main') {
            delete_btn_show = 'none'
            admin_mode = 'none'
          }
          const imageDiffVert = 25 + 2
          return `
                    <style>
                    .dropbtn {
                      font-size: 16px;
                      border: none;
                    }

                    /*.dropdown {*/
                    /*  position: relative;*/
                    /*  display: inline-block;*/
                    /*}*/

                    .dropdown-content {
                      display: none;
                      position: absolute;
                      background-color: #f1f1f1;
                      min-width: 80px;
                      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                      overflow: visible;
                    }

                    .dropdown-content a {
                      color: black;
                      padding-left: 10px;
                      padding-top: 4px;
                      text-decoration: none;
                      display: block;
                    }

                    .dropdown-content a:hover {background-color: #ddd;}

                    .dropdown:hover .dropdown-content {display: block;}

                    .dropdown:hover .dropbtn {background-color: #3e8e41;}
                    </style>

                    <div style='width:${d.width}px;height:${d.height}px;padding-top:${imageDiffVert - 2}px;padding-left:1px;padding-right:1px'>
                        <div style="font-family: 'Inter', sans-serif;background-color:${color};  margin-left:-1px;width:${d.width - 2}px;height:${d.height - imageDiffVert}px;border-radius:10px;border: ${d.data._highlighted || d.data._upToTheRootHighlighted ? '5px solid #E27396"' : '1px solid #E4E2E9"'} >
                            <div class='row justify-content-start ml-50 mt-25'>
                              <div style="display:${options_menu_show} !important" class="dropdown">
                                  <button class="dropbtn">
                                     <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                                  </button>
                                  <div class="dropdown-content">
                                    <a onclick="node_detail_modal('${d.id}')" href="#">${lbl_view}</a>
                                    <a style="display:${edit_btn_show} !important" onclick="node_update_modal('${d.id}')" href="#">${lbl_edit}</a>
                                    <a style="display:${add_btn_show} !important" onclick="node_add_modal('${d.id}')" href="#">${lbl_new_node_add}</a>
                                    <a style="display:${admin_mode} !important" onclick="node_move_modal('${d.id}')" href="#">${lbl_move}</a>
                                    <a style="display:${delete_btn_show} !important" onclick="node_delete_modal('${d.id}')" href="#">${lbl_delete}</a>
                                  </div>
                              </div>
                            </div>
                            <div style="text-decoration: underline;font-size:15px;color:#08011E;" class="text-center">
                                <b>${d.data.name}</b>
                            </div>
                            <div class="row justify-content-around mx-auto mt-2 text-white">
                                <div style="display:${options_menu_show} !important" class="border-bottom" >
                                    <div data-toggle="tooltip" data-placement="bottom" title="${lbl_title_number_of_qr_code_readings}">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg> ${d.data.qrcode_read_count}
                                    </div>
                                </div>
                                <div style="display:${options_menu_show} !important" class="border-bottom">
                                  <div data-toggle="tooltip"  data-placement="bottom" title="${lbl_title_total_number_of_feedback}">
<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-cw"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg> ${d.data.feedback_count}
                                  </div>
                                </div>
                                <div style="display:${options_menu_show} !important" class="border-bottom">
                                    <div data-toggle="tooltip" data-placement="bottom" title="${lbl_title_number_of_open_feedback}">
<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-help-circle"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg> ${d.data.open_notification_count}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
          `
        })
        .data(data)
        .render()
      this.chartReference.expandAll().fit()
    },
  },
}
</script>
