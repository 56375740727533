var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"qrcode_download_helper",attrs:{"id":"qrcode_download_helper","centered":"","no-close-on-backdrop":"","content-class":"shadow","title":_vm.$t('qrcode_download_helper.btn_qrcode_download_helper'),"footer-class":"d-none","size":"xl"},on:{"hide":_vm.close_qrcode_download_helper}},[_c('b-row',{staticClass:"justify-content-center"},[(_vm.preparation_status == false)?_c('b-col',{staticClass:"border",attrs:{"cols":"4"}},[_c('validation-observer',{ref:"insertSurveyGroupForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.qrcode_download_helper_button($event)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('qrcode_download_helper.sub_nodes_insert')))]),_c('validation-provider',{attrs:{"name":"sub_nodes_insert","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mx-25",attrs:{"id":"survey_group","options":_vm.sub_nodes_insert_options,"state":errors.length > 0 ? false: _vm.sub_nodes_insert != null ? true : null,"label":"title","clearable":false,"placeholder":_vm.$t('qrcode_download_helper.sub_nodes_insert')},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
var icon = ref.icon;
return undefined}}],null,true),model:{value:(_vm.sub_nodes_insert),callback:function ($$v) {_vm.sub_nodes_insert=$$v},expression:"sub_nodes_insert"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('qrcode_download_helper.export_render_method')))]),_c('validation-provider',{attrs:{"name":"export_render_method","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mx-25",attrs:{"id":"export_render_method","options":_vm.export_render_method_options,"state":errors.length > 0 ? false: _vm.export_render_method != null ? true : null,"label":"title","clearable":false,"placeholder":_vm.$t('qrcode_download_helper.export_render_method')},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
var icon = ref.icon;
return undefined}}],null,true),model:{value:(_vm.export_render_method),callback:function ($$v) {_vm.export_render_method=$$v},expression:"export_render_method"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1)],1)],1),(_vm.sub_nodes_insert.value==true&&_vm.export_render_method.value=='custom_design')?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('qrcode_download_helper.marker_location')))]),_c('validation-provider',{attrs:{"name":"marker_location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mx-25",attrs:{"id":"marker_location","options":_vm.marker_location_options,"state":errors.length > 0 ? false: _vm.marker_location != null ? true : null,"label":"title","clearable":false,"placeholder":_vm.$t('qrcode_download_helper.marker_location')},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
var icon = ref.icon;
return undefined}}],null,true),model:{value:(_vm.marker_location),callback:function ($$v) {_vm.marker_location=$$v},expression:"marker_location"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('qrcode_download_helper.marker_size')))]),_c('validation-provider',{attrs:{"name":"marker_size","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"marker_size","size":"md","placeholder":_vm.$t('qrcode_download_helper.marker_size')},model:{value:(_vm.marker_size),callback:function ($$v) {_vm.marker_size=$$v},expression:"marker_size"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('qrcode_download_helper.marker_color')))]),_c('validation-provider',{attrs:{"name":"marker_color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('color-picker',{attrs:{"color":_vm.marker_color,"no-input":"","picker":"compact"},model:{value:(_vm.marker_color),callback:function ($$v) {_vm.marker_color=$$v},expression:"marker_color"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"font-size":"10px"}},[_c('u',[_vm._v(_vm._s(_vm.$t('qrcode_download_helper.indicator'))+":")]),_vm._v(" "+_vm._s(_vm.$t('qrcode_download_helper.indicator_text'))+" ")])])],1):_vm._e(),_c('hr'),_c('b-row',{staticClass:"justify-content-end"},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.export_btn}},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('qrcode_download_helper.download'))+" "),_c('i',{staticClass:"pi pi-download"})])])],1)],1)]}}],null,false,794458494)})],1):_vm._e(),_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"6"}},[_c('b-row',{staticClass:"justify-content-center text-center"},[(_vm.preparation_status == true)?_c('b-col',{staticClass:"mb-25",attrs:{"cols":"12"}},[_c('div',{staticClass:"progress-wrapper"},[_c('b-card-text',{staticClass:"mb-0"},[(_vm.curently_counter!=_vm.total_qrcode_design)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('qrcode_download_helper.design_rendering'))+" ( "+_vm._s(_vm.curently_counter)+" / "+_vm._s(_vm.total_qrcode_design)+") ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('qrcode_download_helper.design_rendered'))+" ")])]),_c('b-progress',{staticClass:"progress-bar-warning",attrs:{"max":_vm.total_qrcode_design},model:{value:(_vm.curently_counter),callback:function ($$v) {_vm.curently_counter=$$v},expression:"curently_counter"}})],1)]):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[(_vm.previewImage)?_c('img',{staticClass:"border border-dark",staticStyle:{"max-height":"500px"},attrs:{"src":_vm.previewImage,"alt":"Canvas Preview"}}):_vm._e()])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }