<template>
  <div>
    <!--    Ekle Modal-->
    <b-modal
      id="node_user_access_modal"
      ref="node_user_access_modal"
      no-close-on-backdrop
      content-class="shadow"
      footer-class="d-none"
      :title="$t('user_access_tab.modal_new_add_title')"
      size="lg"
      centered
      @hide="close_node_user_access_modal"
    >
      <!-- TAB Email Check      -->
      <div>
        <b-row>
          <b-alert variant="primary" show>
            <div class="alert-body">
              <span>{{$t('user_access_tab.modal_new_add_warning_message', {'node_name': selected_row_data.name})}}</span>
            </div>
          </b-alert>

        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="$t('user_access_tab.modal_new_add_user_email')"
              label-for="user_mail"
            >
              <b-input-group>
                <b-form-input
                  v-model="user_mail"
                  :disabled="user_status===true"
                  :placeholder="$t('user_access_tab.modal_new_add_user_email')"
                  name="user_mail"
                  type="email"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="user_status===true"
                    variant="outline-primary"
                    @click="check_company_user"
                  >
                    {{$t('user_access_tab.modal_new_add_btn_check')}}
                  </b-button>
                </b-input-group-append>
              </b-input-group>

            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-show="user_status==false">
          <b-alert
            variant="danger"
            show
            class="w-100"
          >
            <h4 class="alert-heading">
              {{$t('core.error')}}
            </h4>
            <div class="alert-body">
              <span>{{check_error_message}}</span>
            </div>
          </b-alert>
        </b-row>
        <hr>
        <b-row v-if="user_status==true">
          <b-alert variant="warning" show>
            <h4 class="alert-heading">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">{{$t("user_access_tab.modal_new_add_permissions")}}</span>
            </h4>
            <div class="alert-body">
              <span>{{$t('user_access_tab.modal_new_add_warning_message_2', {user_name:user_name, node_name: selected_row_data.name })}}</span>
            </div>
          </b-alert>
        </b-row>
        <b-row v-if="user_status==true">
          <b-table
            striped
            responsive
            class="mb-0"
            :items="permissionsData"
            :fields="permissionstableColumns"
          >
            <template #cell(module)="data">
              <span v-if="data.value==='user'">{{$t('user_access_tab.tbl_clomun_user_management')}}</span>
              <span v-if="data.value==='node'">{{$t('user_access_tab.tbl_clomun_node_management')}}</span>
              <span v-if="data.value==='qr_code'">{{$t('user_access_tab.tbl_clomun_qr_management')}}</span>
              <span v-if="data.value==='feed_back'">{{$t('user_access_tab.tbl_clomun_feedback_management')}}</span>
            </template>
            <template #cell()="data">
              <b-form-checkbox v-model="data.item[data.field.key]" :checked="data.item[data.field.key]" />
            </template>
          </b-table>
        </b-row>
      </div>
      <!-- TAB Email Check      -->
      <hr>
      <b-row class="justify-content-end">
        <b-button
          v-show="user_status==true"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-danger"
          class="mr-1"
          @click="save_company_user"
        >
          <span class="align-middle">{{ $t('core.btn_add') }}</span>
          <feather-icon
            icon="SaveIcon"
            class="ml-50"
          />
        </b-button>
      </b-row>
    </b-modal>
    <!--    Ekle Modal-->

    <!--    Düzenle Modal-->
    <b-modal
      id="node_user_access_modal_edit"
      ref="node_user_access_modal_edit"
      no-close-on-backdrop
      content-class="shadow"
      footer-class="d-none"
      :title="$t('user_access_tab.modal_new_edit_title')"
      size="lg"
      centered
      @hide="close_node_user_access_modal_edit"
    >
      <!-- TAB Email Check      -->
      <div>
        <b-row>
          <b-alert
            variant="warning"
            show
          >
            <h4 class="alert-heading">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">{{$t('user_access_tab.modal_new_add_permissions')}}</span>
            </h4>
            <div class="alert-body">
              <span>{{$t('user_access_tab.modal_new_add_warning_message_2', {user_name:selected_row_name, node_name: selected_row_data.name })}}</span>
            </div>
          </b-alert>
        </b-row>
        <b-row>
          <b-table
            striped
            responsive
            class="mb-0"
            :items="permissionsEditData"
            :fields="permissionstableColumns"
          >
            <template #cell(module)="data">
              <span v-if="data.value==='user'">{{ $t('user_access_tab.tbl_clomun_user_management')}}</span>
              <span v-if="data.value==='node'">{{ $t('user_access_tab.tbl_clomun_node_management')}}</span>
              <span v-if="data.value==='qr_code'">{{ $t('user_access_tab.tbl_clomun_qr_management')}}</span>
              <span v-if="data.value==='feed_back'">{{ $t('user_access_tab.tbl_clomun_feedback_management')}}</span>
            </template>
            <template #cell()="data">
              <b-form-checkbox v-model="data.item[data.field.key]" />
            </template>
          </b-table>
        </b-row>
      </div>
      <!-- TAB Email Check      -->
      <hr>
      <b-row class="justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-danger"
          class="mr-1"
          @click="record_edit_button"
        >
          <span class="align-middle">{{$t('user_access_tab.btn_update')}}</span>
          <feather-icon
            icon="SaveIcon"
            class="ml-50"
          />
        </b-button>
      </b-row>
    </b-modal>
    <!--    Düzenle Modal-->

    <!--    Sil Modal-->
    <b-modal
      id="delete_record_modal"
      ref="delete_record_modal"
      class="danger"
      centered

      no-close-on-backdrop
      content-class="shadow"
      :title="$t('user_access_tab.modal_new_edit_delete')"
      footer-class="d-none"
      size="md"
      @hide="close_delete_record_modal"
    >
      <div>
        <b-card-text class="text-left">
          <span>{{$t('user_access_tab.modal_delete_warning_message', {'user_name': selected_row_name})}}</span>
        </b-card-text>
        <b-row class="justify-content-end">
          <b-button
            size="sm"
              variant="primary"
            class="mr-1"
            @click="close_delete_record_modal()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-1"
            />
            <span class="align-middle">{{$t('core.btn_cancel')}}</span>
          </b-button>
          <b-button
            variant="danger"
size="sm"
            class="mr-1"
            @click="record_delete_button()"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-1"
            />
            <span class="align-middle">{{$t('core.btn_delete')}}</span>
          </b-button>
        </b-row>
      </div>
    </b-modal>
    <!--    Sil Modal-->

    <!--    Content-->
    <div>
      <b-row class="justify-content-center">
        <span class="text-white bg-danger text-center text-bold">
          <feather-icon
            icon="AlertTriangleIcon"
            class="mx-50"
          />
          {{$t('user_access_tab.warning_header')}}
          <feather-icon
            icon="AlertTriangleIcon"
            class="mx-50"
          />
        </span>
      </b-row>
      <b-row class="my-50">
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >

          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />

        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('core.search')"
            />
            <b-button
              v-b-modal.node_user_access_modal
              variant="primary"
            >
              <span class="text-nowrap">{{ $t('core.btn_new_add') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-table
          responsive
          :items="table_data"
          :fields="tableColumns"
          primary-key="id"
          show-empty
          fixed
          style="min-height: 400px !important;"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          :empty-text="$t('core.no_records_found')"
          small
          borderless
          striped
        >
          <!-- Column: company_user -->
          <template #cell(company_user)="data">
            {{ data.item.company_user.user.name }}
          </template>

          <!-- Column: company_user -->
          <template #cell(user_email)="data">
            {{ data.item.company_user.user.email }}
          </template>

          <!-- Column: user -->
          <template #cell(user)="data">
            <div class="text-nowrap">
              <b-badge
                  v-if="data.item.user_read"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_read')"
                  class="mr-50"
                  variant="success"
              >
                <feather-icon icon="EyeIcon" />
              </b-badge>
              <b-badge
                  v-if="data.item.user_write"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_edit')"
                  class="mr-50"
                  variant="warning"
              >
                <feather-icon icon="Edit2Icon" />
              </b-badge>
              <b-badge
                  v-if="data.item.user_create"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_create')"
                  class="mr-50"
                  variant="secondary"
              >
                <feather-icon icon="CheckIcon" />
              </b-badge>
              <b-badge
                  v-if="data.item.user_delete"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_delete')"
                  class="mr-50"
                  variant="danger"
              >
                <feather-icon icon="TrashIcon" />
              </b-badge>
            </div>
          </template>

          <!-- Column: node -->
          <template #cell(node)="data">
            <div class="text-nowrap">
            <b-badge
              v-if="data.item.node_read"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_read')"
              class="mr-50"
              variant="success"
            >
              <feather-icon icon="EyeIcon" />
            </b-badge>
            <b-badge
              v-if="data.item.node_write"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_edit')"
              class="mr-50"
              variant="warning"
            >
              <feather-icon icon="Edit2Icon" />
            </b-badge>
            <b-badge
              v-if="data.item.node_create"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_create')"
              class="mr-50"
              variant="secondary"
            >
              <feather-icon icon="CheckIcon" />
            </b-badge>
            <b-badge
              v-if="data.item.node_delete"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_delete')"
              class="mr-50"
              variant="danger"
            >
              <feather-icon icon="TrashIcon" />
            </b-badge>
            </div>
          </template>

          <!-- Column: qrcode -->
          <template #cell(qrcode)="data">
            <div class="text-nowrap">
            <b-badge
              v-if="data.item.qrcode_read"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_read')"
              class="mr-50"
              variant="success"
            >
              <feather-icon icon="EyeIcon" />
            </b-badge>
            <b-badge
              v-if="data.item.qrcode_write"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_edit')"
              class="mr-50"
              variant="warning"
            >
              <feather-icon icon="Edit2Icon" />
            </b-badge>
            <b-badge
              v-if="data.item.qrcode_create"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_create')"
              class="mr-50"
              variant="secondary"
            >
              <feather-icon icon="CheckIcon" />
            </b-badge>
            <b-badge
              v-if="data.item.qrcode_delete"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_delete')"
              class="mr-50"
              variant="danger"
            >
              <feather-icon icon="TrashIcon" />
            </b-badge>
            </div>
          </template>

          <!-- Column: feedback -->
          <template #cell(feedback)="data">
            <div class="text-nowrap">
            <b-badge
              v-if="data.item.feedback_read"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_read')"
              class="mr-50"
              variant="success"
            >
              <feather-icon icon="EyeIcon" />
            </b-badge>
            <b-badge
              v-if="data.item.feedback_write"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_edit')"
              class="mr-50"
              variant="warning"
            >
              <feather-icon icon="Edit2Icon" />
            </b-badge>
            <b-badge
              v-if="data.item.feedback_create"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_create')"
              class="mr-50"
              variant="secondary"
            >
              <feather-icon icon="CheckIcon" />
            </b-badge>
            <b-badge
              v-if="data.item.feedback_delete"
                  v-b-tooltip.hover.bottom="$t('user_access_tab.hover_delete')"
              class="mr-50"
              variant="danger"
            >
              <feather-icon icon="TrashIcon" />
            </b-badge>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(id)="data">
            <div class="text-nowrap">
              <!-- Dropdown -->
              <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="rowUpdateModal(data.item.id, )">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t('user_access_tab.btn_edit') }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="rowDeleteModal(data.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t('user_access_tab.btn_delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <!--    Content-->
  </div>
</template>
<script>
import {
  BAlert, BBadge,
  BButton,
  BButtonGroup, BCardText,
  BCol,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput, BInputGroup,
  BInputGroupAppend,
  BLink,
  BModal,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCardText,
    BBadge,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BFormCheckbox,
    BFormGroup,
    BTab,
    BTabs,
    BPagination,
    BTable,
    vSelect,
    BLink,
    BFormInput,
    BCol,
    BRow,
    BAlert,
    BButton,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    selected_row_data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      check_error_message: null,
      selected_row_name: null,
      selected_row_id: null,
      company_user_uid: null,
      user_mail: null,
      user_name: null,
      user_status: null,
      perPageOptions: [25, 50, 100, 500, 1000],
      sortBy: null,
      isSortDirDesc: null,
      searchQuery: null,
      roleOptions: [],
      table_data: [],
      tableColumns: [
        { key: 'company_user', label: this.$t('user_access_tab.tbl_clomun_user'), sortable: true },
        { key: 'user_email', label: this.$t('user_access_tab.tbl_clomun_email'), sortable: true },
        { key: 'user', label: this.$t('user_access_tab.tbl_clomun_user_management'), sortable: false },
        { key: 'node', label: this.$t('user_access_tab.tbl_clomun_node_management'), sortable: false },
        { key: 'qrcode', label: this.$t('user_access_tab.tbl_clomun_qr_management'), sortable: false },
        { key: 'feedback', label: this.$t('user_access_tab.tbl_clomun_feedback_management'), sortable: false },
        { key: 'id', label: '#' },
      ],
      permissionstableColumns: [
        { key: 'module', label: this.$t('user_access_tab.permiision_table_column_module'), sortable: true },
        { key: 'read', label: this.$t('user_access_tab.permiision_table_column_read'), sortable: true },
        { key: 'write', label: this.$t('user_access_tab.permiision_table_column_edit'), sortable: true },
        { key: 'create', label: this.$t('user_access_tab.permiision_table_column_create'), sortable: true },
        { key: 'delete', label: this.$t('user_access_tab.permiision_table_column_delete'), sortable: true },
      ],
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      permissionsData: [
        {
          module: 'user',
          read: true,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: 'node',
          read: true,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: 'qr_code',
          read: true,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: 'feed_back',
          read: true,
          write: false,
          create: false,
          delete: false,
        },
      ],
      permissionsEditData: null,

    }
  },
  watch: {
    currentPage: { handler() { this.getTableData() } },
    sortBy: { handler() { this.getTableData() } },
    isSortDirDesc: { handler() { this.getTableData() } },
    searchQuery: {
      handler() {
        this.getTableData()
      },
    },
  },
  created() {
    this.getTableData()
  },
  mounted() {},
  methods: {
    check_company_user() {
      const formData = new FormData()
      formData.append('company', this.selected_row_data.company)
      formData.append('organization', this.selected_row_data.organization)
      formData.append('node', this.selected_row_data.id)
      formData.append('email', this.user_mail)
      useJwt.check_company_user(formData)
        .then(response => {
          if (response.data.error_status === false) {
            this.user_name = response.data.full_name
            this.user_status = true
            this.company_user_uid = response.data.company_user_uid
          } else {
            this.user_status = false
            this.check_error_message = response.data.error_message

          }
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    save_company_user() {
      const formData = new FormData()
      formData.append('company', this.selected_row_data.company)
      formData.append('organization', this.selected_row_data.organization)
      formData.append('node', this.selected_row_data.id)
      formData.append('company_user', this.company_user_uid)
      this.permissionsData.forEach(perm => {
        if (perm.module === 'user') {
          formData.append('user_read', perm.read)
          formData.append('user_write', perm.write)
          formData.append('user_create', perm.create)
          formData.append('user_delete', perm.delete)
        } else if (perm.module === 'node') {
          formData.append('node_read', perm.read)
          formData.append('node_write', perm.write)
          formData.append('node_create', perm.create)
          formData.append('node_delete', perm.delete)
        } else if (perm.module === 'qr_code') {
          formData.append('qrcode_read', perm.read)
          formData.append('qrcode_write', perm.write)
          formData.append('qrcode_create', perm.create)
          formData.append('qrcode_delete', perm.delete)
        } else if (perm.module === 'feed_back') {
          formData.append('feedback_read', perm.read)
          formData.append('feedback_write', perm.write)
          formData.append('feedback_create', perm.create)
          formData.append('feedback_delete', perm.delete)
        }
      })
      useJwt.createNodeuseraccess(formData)
        .then(response => {
          this.getTableData()
          this.close_node_user_access_modal()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    getTableData() {
      useJwt.getNodeuseraccessList({
        // center_type: this.filter_center_type.value,
        page: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        company: this.selected_row_data.company,
        organization: this.selected_row_data.organization,
        node: this.selected_row_data.id,
      })
        .then(response => {
          this.table_data = response.data.results
          this.totalRows = response.data.count
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    close_node_user_access_modal() {
      this.$refs.node_user_access_modal.hide()
      this.user_mail = null
      this.check_error_message = null
      this.user_name = null
      this.user_status = null
      this.company_user_uid = null
    },
    close_node_user_access_modal_edit() {
      this.$refs.node_user_access_modal_edit.hide()
      this.permissionsEditData = null
      this.selected_row_id = null
    },
    rowUpdateModal(row_id) {
      useJwt.NodeuseraccessDetail(row_id)
        .then(response => {
          this.selected_row_name = response.data.company_user.user.name
          this.selected_row_id = response.data.id
          this.permissionsEditData = [
            {
              module: 'user',
              read: response.data.user_read,
              write: response.data.user_write,
              create: response.data.user_create,
              delete: response.data.user_delete,
            }, {
              module: 'node',
              read: response.data.node_read,
              write: response.data.node_write,
              create: response.data.node_create,
              delete: response.data.node_delete,
            },
            {
              module: 'qr_code',
              read: response.data.qrcode_read,
              write: response.data.qrcode_write,
              create: response.data.qrcode_create,
              delete: response.data.qrcode_delete,
            },
            {
              module: 'feed_back',
              read: response.data.feedback_read,
              write: response.data.feedback_write,
              create: response.data.feedback_create,
              delete: response.data.feedback_delete,
            },
          ]
          this.$refs.node_user_access_modal_edit.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    record_edit_button() {
      const formData = new FormData()
      this.permissionsEditData.forEach(perm => {
        if (perm.module === 'user') {
          formData.append('user_read', perm.read)
          formData.append('user_write', perm.write)
          formData.append('user_create', perm.create)
          formData.append('user_delete', perm.delete)
        } else if (perm.module === 'node') {
          formData.append('node_read', perm.read)
          formData.append('node_write', perm.write)
          formData.append('node_create', perm.create)
          formData.append('node_delete', perm.delete)
        } else if (perm.module === 'qr_code') {
          formData.append('qrcode_read', perm.read)
          formData.append('qrcode_write', perm.write)
          formData.append('qrcode_create', perm.create)
          formData.append('qrcode_delete', perm.delete)
        } else if (perm.module === 'feed_back') {
          formData.append('feedback_read', perm.read)
          formData.append('feedback_write', perm.write)
          formData.append('feedback_create', perm.create)
          formData.append('feedback_delete', perm.delete)
        }
      })
      useJwt.updateNodeuseraccess(this.selected_row_id, formData)
        .then(response => {
          this.getTableData()
          this.close_node_user_access_modal_edit()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('user_access_tab.permission_updated'),
              icon: 'CheckCircleIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    rowDeleteModal(row_id) {
      useJwt.NodeuseraccessDetail(row_id)
        .then(response => {
          this.selected_row_name = response.data.company_user.user.name
          this.selected_row_id = response.data.id
          this.$refs.delete_record_modal.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    record_delete_button() {
      useJwt.NodeuseraccessDelete(this.selected_row_id)
        .then(response => {
          this.getTableData()
          this.close_delete_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('user_access_tab.permission_deleted'),
              icon: 'CheckCircleIcon',
              variant: 'danger',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    close_delete_record_modal() {
      this.$refs.delete_record_modal.hide()
      this.selected_row_id = null
    },

  },
}
</script>
