<template>
  <div>
    <b-row class="justify-content-center">
      <b-col
        v-if="organization_data == null"
        class=" mt-1"
        md="4"
      >
        <b-card class="mb-0">
          <b-alert
            class="text-center mt-1"
            show
            variant="warning"
          >
            <h4 class="alert-heading">
              Organizasyon Bulunamdı!
            </h4>
          </b-alert>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.new_record_modal_main_organization
            block
            class="mt-3"
            variant="primary"
          >
            Yeni Organizasyon Oluştur
          </b-button>
        </b-card>
      </b-col>
      <b-col>
        <OrganizationRenderer
            :child_nodes="child_nodes"
            :parent_nodes="parent_nodes"
            :customer_is_admin="customer_is_admin"
            :organization_data="organization_data"
            :pp="pp"
            :update_organization_data="getCompanyOrganizationNodesList"
            :get_user_node_access_list="get_user_node_access_list"
        />
      </b-col>
    </b-row>
  </div>

</template>
<script>
import {
  BAlert,
  BButton,
  BModal,
  VBModal,
  BTabs,
  BTab,
  BContainer,
  BRow,
  BCol,
  BFormSelect,
  BCard,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSpinbutton, BBreadcrumbItem, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import OrganizationRenderer from '@/views/custom_app/client/organization_management/components/OrganizationRenderer.vue'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import router from '@/router'

export default {
  components: {
    BCardText,
    BBreadcrumbItem,
    BCard,
    BAlert,
    BButton,
    BCol,
    BTab,
    BTabs,
    BContainer,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BFormSpinbutton,
    OrganizationRenderer,
    BFormRadio,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      organization_data: [],
      child_nodes: [],
      parent_nodes: [],
      pp: [],
      customer_is_admin: false,
    }
  },
  created() {
    this.getCompanyOrganizationNodesList()
    this.get_user_node_access_list()
  },
  methods: {
    getCompanyOrganizationNodesList() {
      useJwt.getCompanyOrganizationNodesList({
        organization: router.currentRoute.params.id,
        page_size: 50000,
        company: getUserData().company_id,
      })
        .then(response => {
          this.organization_data = response.data.results
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    get_user_node_access_list() {
      const formData = new FormData()
      formData.append('organization', router.currentRoute.params.id)
      formData.append('company', getUserData().company_id)
      useJwt.getUserNodeAccessList(formData)
        .then(response => {
          this.child_nodes = response.data.child_nodes
          this.parent_nodes = response.data.parent_nodes
          this.customer_is_admin = response.data.customer_is_admin
          this.pp = response.data.pp
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>
