<template>
  <div>
    <!--    Gruba Ekle Modal-->
    <b-modal
      id="insert_survey_group"
      ref="insert_survey_group"
      centered
      no-close-on-backdrop
      content-class="shadow"
      :title="$t('survey_tab.btn_insert_survey_group')"
      footer-class="d-none"
      size="md"
      @hide="close_insert_survey_group"
    >
      <div>
        <validation-observer
          ref="insertSurveyGroupForm"
          #default="{invalid}"
        >
          <b-form @submit.prevent="insert_survey_group_button">
            <b-row>
              <b-col
                cols="12"
                class="mb-md-0 mb-2"
              >
                <b-form-group>
                  <label for="name"><span class="text-danger text-bold">*</span> {{ $t('survey_tab.survey_group') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="survey_group"
                    rules="required"
                  >
                    <v-select
                      id="survey_group"
                      v-model="survey_group"
                      :options="survey_group_options"
                      :state="errors.length > 0 ? false: survey_group != null ? true : null"
                      label="group_name"
                      :clearable="false"
                      class="mx-25"
                      :placeholder="$t('survey_tab.survey_group')"
                    >
                      <template #option="{ title, icon }" />
                    </v-select>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row class="justify-content-end">
              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                type="submit"
                :disabled="invalid"
              >
                <span class="align-middle">{{ $t('survey_tab.include_in_group') }}</span>
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </div>

    </b-modal>
    <!--    Gruba Ekle Modal-->
    <!--    Farklı Gruba Taşı Modal-->
    <b-modal
      id="move_survey_group"
      ref="move_survey_group"
      centered
      no-close-on-backdrop
      content-class="shadow"
      :title="$t('survey_tab.btn_move_survey_group')"
      footer-class="d-none"
      size="md"
      @hide="close_move_survey_group"
    >
      <div>
        <validation-observer
          ref="moveSurveyGroupForm"
          #default="{invalid}"
        >
          <b-form @submit.prevent="move_survey_group_button">
            <b-row>
              <b-col
                cols="12"
                class="mb-md-0 mb-2"
              >
                <b-form-group>
                  <label for="name"><span class="text-danger text-bold">*</span> {{ $t('survey_tab.survey_group') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="survey_group"
                    rules="required"
                  >
                    <v-select
                      id="survey_group"
                      v-model="move_survey_group"
                      :options="survey_group_options"
                      :state="errors.length > 0 ? false: survey_group != null ? true : null"
                      label="group_name"
                      :clearable="false"
                      class="mx-25"
                      :placeholder="$t('survey_tab.survey_group')"
                    >
                      <template #option="{ title, icon }" />
                    </v-select>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row class="justify-content-end">
              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                type="submit"
                :disabled="invalid"
              >
                <span class="align-middle">{{ $t('survey_tab.btn_move_survey_group') }}</span>
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </div>

    </b-modal>
    <!--    Farklı Gruba Taşı Modal-->
    <!--    Özel Tasarıma Çevir Modal-->
    <b-modal
      id="convert_survey_group_to_custom_design"
      ref="convert_survey_group_to_custom_design"
      centered
      no-close-on-backdrop
      content-class="shadow"
      :title="$t('survey_tab.btn_custom_design')"
      footer-class="d-none"
      size="md"
      variant="warning"
      @hide="close_convert_survey_group_to_custom_design"
    >
      <div>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <h6>{{ $t('survey_tab.custom_design_warning_title') }}</h6>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-between mx-1">
          <b-button
            size="sm"
            variant="secondary"
            @click="close_convert_survey_group_to_custom_design"
          >
            <span class="align-middle">{{ $t('survey_tab.btn_custom_design_cancel') }}</span>
          </b-button>
          <b-button
            size="sm"
            variant="warning"
            @click="btn_custom_design_ok"
          >
            <span class="align-middle">{{ $t('survey_tab.btn_custom_design_ok') }}</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Özel Tasarıma Çevir Modal-->
    <b-row v-if="selected_node.data.survey_is_setup==false">
      <b-col cols="12">
        <b-alert
          variant="warning"
          show
        >
          <h4 class="alert-heading">
            {{ $t('survey_tab.warning_title') }}
          </h4>
          <div class="alert-body">
            <span>{{ $t('survey_tab.warning_message') }}</span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        class="justify-content-around"
        cols="12"
      >
        <b-row class="justify-content-around">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="setup_survey_node_button()"
          >
            <span class="align-middle">{{ $t('survey_tab.btn_custom_design') }}</span>
            <feather-icon
              icon="ArrowRightIcon"
              class="ml-50"
            />
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.insert_survey_group
            variant="outline-primary"
          >
            <span class="align-middle">{{ $t('survey_tab.btn_insert_survey_group') }}</span>
            <feather-icon
              icon="ArrowRightIcon"
              class="ml-50"
            />
          </b-button>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="selected_node.data.survey_is_setup==true">
      <b-col cols="12">
        <b-row class="justify-content-end">
          <b-button-group>
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              size="sm"
              variant="outline-primary"
              :text="$t('survey_tab.btn_edit')"
            >
              <b-dropdown-item
                v-if="selected_node.data.survey_type =='one'"
                @click="$router.push({ name: 'survey-management', query: { id: temp_survey_id, survey_type: 'one' } })"
              >
                <span
                  size="sm"
                  class="align-middle"
                >{{ $t("survey_tab.btn_survey_edit") }} </span><feather-icon
                  icon="ArrowRightIcon"
                  class="ml-50"
                />
              </b-dropdown-item>
              <b-dropdown-item
                v-if="selected_node.data.survey_type =='one'"
                v-b-modal.insert_survey_group
              >
                <span
                  size="sm"
                  class="align-middle"
                >{{ $t("survey_tab.btn_insert_survey_group") }}</span><feather-icon
                  icon="ArrowRightIcon"
                  class="ml-50"
                />
              </b-dropdown-item>
              <!--                      <b-dropdown-item v-if="selected_node.data.survey_type =='one'"><span size="sm" class="align-middle">Yeni Anket Grubu Oluştur</span><feather-icon icon="ArrowRightIcon" class="ml-50"/></b-dropdown-item>-->
              <b-dropdown-item
                v-if="selected_node.data.survey_type =='group'"
                @click="$router.push({ name: 'survey-management', query: { id: temp_survey_id, survey_type: 'group' } })"
              >
                <span
                  size="sm"
                  class="align-middle"
                >{{ $t("survey_tab.btn_survey_group_design_edit") }}</span><feather-icon
                  icon="ArrowRightIcon"
                  class="ml-50"
                />
              </b-dropdown-item>
              <b-dropdown-item
                v-if="selected_node.data.survey_type =='group'"
                v-b-modal.move_survey_group
              >
                <span
                  size="sm"
                  class="align-middle"
                >{{ $t("survey_tab.btn_insert_other_survey_group") }}</span><feather-icon
                  icon="ArrowRightIcon"
                  class="ml-50"
                />
              </b-dropdown-item>
              <b-dropdown-item
                v-if="selected_node.data.survey_type =='group'"
                v-b-modal.convert_survey_group_to_custom_design
              >
                <span
                  size="sm"
                  class="align-middle"
                >{{ $t("survey_tab.btn_custom_design") }}</span><feather-icon
                  icon="ArrowRightIcon"
                  class="ml-50"
                />
              </b-dropdown-item>
            </b-dropdown>
          </b-button-group>
        </b-row>
        <hr>
      </b-col>
      <b-col
        v-if="iframeSrc!== null"
        cols="12"
        class="d-flex justify-content-center"
      >
        <iframe
          :src="iframeSrc"
          style="max-height: 600px; width: 100vh"
          height="600px"
          frameborder="0"
          allowfullscreen
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BAlert,
  BButton,
  BButtonGroup,
  BCol,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BForm, BFormCheckbox, BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'survey-core/defaultV2.css'
import { Model } from 'survey-core'
import { Survey } from 'survey-vue-ui'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    vSelect,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    BFormInput,
    BCol,
    BRow,
    Survey,
    BDropdown,
    BDropdownDivider,
    BAlert,
    BDropdownItem,
    BButton,
    BButtonGroup,
    BModal,
    Survey,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    selected_node: {
      type: Object,
      required: true,
    },
    selected_node_id: {
      type: String,
      required: true,
    },
    close_view_record_modal_selected_noda: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      update_count: 0,
      survey: null,
      iframeSrc: null,
      temp_survey_id: null,
      survey_group: null,
      move_survey_group: null,
      survey_group_options: null,
    }
  },
  watch: {},
  created() {
    this.check_survey_status()
    this.getSurveyGroupData()
  },
  methods: {
    check_survey_status() {
      this.update_count += 1
      this.survey = null
      this.iframeSrc = null
      if (this.selected_node.data.survey_is_setup === true) {
        this.iframeSrc = `/survey_preview?view_type=survey_preview&id=${this.selected_node.data.id}&modified=${this.update_count}`
        if (this.selected_node.data.survey_type === 'one') {
          useJwt.getNodeSurveysList({ node: this.selected_node_id })
            .then(response => {
              this.temp_survey_id = response.data.results[0].id
            })
            .catch(error => {
              console.log(error)
            })
        }
        if (this.selected_node.data.survey_type === 'group') {
          useJwt.getGroupNodesList({
            node: this.selected_node_id,
          })
            .then(response => {
              this.temp_survey_id = response.data.results[0].group
            })
            .catch(error => {
              console.log(error.response)
            })
        }
      }
    },
    insert_survey_group_button() {
      this.$refs.insertSurveyGroupForm.validate().then(success => {
        if (success) {
          const node_list = []
          node_list.push(this.selected_node.data.id)
          const formData = new FormData()
          formData.append('group', this.survey_group.id)
          formData.append('company', getUserData().company_id)
          formData.append('organization', this.selected_node.data.organization)
          formData.append('node_list', node_list)
          // formData.append('node_list', [1,2,3])
          useJwt.multiAddSurveyGroups(formData)
            .then(response => {
              this.selected_node_update()
              this.close_insert_survey_group()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('survey_tab.survey_insert_to_group'),
                  icon: 'SaveIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'AlertIcon',
                  variant: 'danger',
                  text: error.response.data.detail,
                },
              })
              console.log(error.response)
            })
        }
      })
    },
    selected_node_update() {
      useJwt.companyOrganizationNodesDetail(this.selected_node.data.id)
        .then(response => {
          this.selected_node.data = response.data
          this.check_survey_status()
        })
    },
    move_survey_group_button() {
      this.$refs.moveSurveyGroupForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('group', this.move_survey_group.id)
          formData.append('node', this.selected_node.data.id)
          // formData.append('node_list', [1,2,3])
          useJwt.nodeMoveToOtherGroup(formData)
            .then(response => {
              this.selected_node_update()
              this.close_move_survey_group()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('survey_tab.survey_group_changed'),
                  icon: 'SaveIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'AlertIcon',
                  variant: 'danger',
                  text: error.response.data.detail,
                },
              })
              console.log(error.response)
            })
        }
      })
    },
    btn_custom_design_ok() {
      const formData = new FormData()
      formData.append('node', this.selected_node.data.id)
      useJwt.convertGroupToOneDesign(formData)
        .then(response => {
          this.$router.push({ name: 'survey-management', query: { id: response.data.survey_id, survey_type: 'one' } })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertIcon',
              variant: 'danger',
              text: error.response.data.detail,
            },
          })
          console.log(error.response)
        })
    },
    getSurveyGroupData() {
      useJwt.getSurveyGroupsList({
        company: getUserData().company_id,
        organization: this.selected_node.data.organization,
        page_size: 5000,
      })
        .then(response => {
          this.survey_group_options = response.data.results
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    setup_survey_node_button() {
      useJwt.setupSurveyCompanyOrganizationNodes(this.selected_node_id)
        .then(response => {
          this.close_view_record_modal_selected_noda()
          this.$router.push({ name: 'survey-management', query: { id: response.data.survey_id, survey_type: 'one' } })
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    close_insert_survey_group() {
      this.$refs.insert_survey_group.hide()
      this.survey_group = null
    },
    close_move_survey_group() {
      this.$refs.move_survey_group.hide()
      this.move_survey_group = null
    },
    close_convert_survey_group_to_custom_design() {
      this.$refs.convert_survey_group_to_custom_design.hide()
    },
  },
}
</script>
