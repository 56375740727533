<template>
  <div>
    <b-row v-if="selected_row_data.survey==null">
      <b-col cols="12">
        <b-alert
        variant="warning"
        show
      >
        <h4 class="alert-heading">
          Anket tasarımı yapılmamış!
        </h4>
        <div class="alert-body">
          <span>Aşağıdaki <b>Tasarıma Başla</b> butonuna tıklayarak kurulum yapabilirsiniz.</span>
        </div>
      </b-alert>
      </b-col>
      <b-col
        class="justify-content-around"
        cols="12"
      >
        <b-row class="justify-content-around">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="setup_survey_node_button()"
          >
            <span class="align-middle">Tasarıma Başla</span>
            <feather-icon
              icon="ArrowRightIcon"
              class="ml-50"
            />
          </b-button>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="selected_row_data.survey!==null">
      <b-col cols="12">
        <b-row class="justify-content-end">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              size="sm"
              @click="setup_survey_node_button()"
          >
            <span class="align-middle">Tasarımı Düzenle</span>
            <feather-icon
                icon="ArrowRightIcon"
                class="ml-50"
            />
          </b-button>
        </b-row>
        <hr>
      </b-col>
      <b-col v-if="iframeSrc!== null" class="d-flex justify-content-center" cols="12">
<!--        <survey :survey="survey" />-->
        <iframe
            :src="iframeSrc"
            width="600"
            height="600"
            frameborder="0"
            allowfullscreen
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BAlert,
  BButton, BButtonGroup, BCol, BDropdown, BDropdownDivider, BDropdownItem,
  BModal, BRow, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'survey-core/defaultV2.css'
import { Model } from 'survey-core'
import { Survey } from 'survey-vue-ui'

export default {
  components: {
    BCol,
    BRow,
    Survey,
    BAlert,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    selected_row_data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      iframeSrc: null,
      survey: null,
      temp_survey_id: null,
    }
  },
  watch: {},
  created() {
    if (this.selected_row_data.survey !== null) {
      this.iframeSrc = `/survey_preview?view_type=survey_group_preview&id=${this.selected_row_data.id}`
      // const surveyJson = this.selected_row_data.survey
      // const ss = new Model(surveyJson)
      // ss.mode = 'display'
      // this.survey = ss
    }
  },
  mounted() {},
  methods: {
    setup_survey_node_button() {
      this.$router.push({ name: 'survey-management', query: { id: this.selected_row_data.id, survey_type: 'group' } })
    },
  },
}
</script>
