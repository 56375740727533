<template>
  <!--    İncele Modal-->
  <b-modal
    id="view_record_modal_selected_noda"
    ref="view_record_modal_selected_noda"
    no-close-on-backdrop
    content-class="shadow"
    footer-class="d-none"
    :title="selected_node.data.name"
    size="xl"
    @hide="close_view_record_modal_selected_noda"
  >
    <div>
      <b-tabs class="mb-0">
        <b-tab
          active>
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>{{ $t('detail_node_modal.tab_title_general') }}</span>
          </template>
          <general-tab :organization_node_list="organization_node_list" :selected_node="selected_node"/>
        </b-tab>
        <b-tab v-if="survey_tab_show">
          <template #title>
            <feather-icon icon="CheckSquareIcon" />
            <span>{{$t('detail_node_modal.tab_title_survey_design')}}</span>
          </template>
          <survey-tab :close_view_record_modal_selected_noda="close_view_record_modal_selected_noda" :selected_node="selected_node" :selected_node_id="selected_node_id"  />
        </b-tab>
        <b-tab v-if="qrcode_tab_show">
          <template #title>
            <i class="pi pi-qrcode"></i>
            <span>{{$t('detail_node_modal.tab_title_qrcode_design')}}</span>
          </template>
          <q-r-code-tab :close_view_record_modal_selected_noda="close_view_record_modal_selected_noda" :selected_node="selected_node" :selected_node_id="selected_node_id"  />
        </b-tab>
        <b-tab v-if="feedback_tab_show">
          <template #title>
            <feather-icon icon="RefreshCwIcon" />
            <span>{{$t('detail_node_modal.tab_title_feedback_design')}}</span>
          </template>
          <feedback-management :selected_node="selected_node" />
        </b-tab>
        <b-tab v-if="user_management_tab_show">
          <template #title>
            <feather-icon icon="UsersIcon" />
            <span>{{$t('detail_node_modal.tab_title_user_management')}}</span>
          </template>
          <user-management-tab :selected_row_data="selected_node.data"></user-management-tab>
        </b-tab>
      </b-tabs>
    </div>

  </b-modal>
  <!--    İncele Modal-->
</template>
<script>
import {
  BAlert,
  BButton, BCol, BDropdown, BDropdownItem,
  BModal, BRow, BTab, BTabs, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Survey } from 'survey-vue-ui'
import useJwt from '@/auth/jwt/useJwt'
import GeneralTab from "@/views/custom_app/client/organization_management/components/detail_node_modal/GeneralTab.vue"
import SurveyTab from "@/views/custom_app/client/organization_management/components/detail_node_modal/SurveyTab.vue"
import UserManagementTab
  from "@/views/custom_app/client/organization_management/components/user_management/UserManagementTab.vue"
import FeedbackManagement from "@/views/custom_app/client/feedback_management/FeedbackManagement.vue";
import QRCodeTab from "@/views/custom_app/client/organization_management/components/detail_node_modal/QRCodeTab.vue";
export default {
  components: {
    FeedbackManagement,
    GeneralTab,
    SurveyTab,
    UserManagementTab,
    BTab,
    BTabs,
    BModal,
    BAlert,
    QRCodeTab,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    organization_node_list: {
      type: Array,
      required: true,
    },
    pp: {
      type: Array,
      required: true,
    },
    customer_is_admin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selected_node_id: null,
      survey_tab_show: false,
      qrcode_tab_show: false,
      qr_code_url: null,
      feedback_tab_show: false,
      user_management_tab_show: false,
      selected_node: { data: { name: '' } },
    }
  },
  watch: {},
  created() {
    window.node_detail_modal = this.node_detail_modal
  },
  mounted() {},
  methods: {
    close_view_record_modal_selected_noda() {
      this.selected_node = { data: { name: '' } }
      this.$refs.view_record_modal_selected_noda.hide()
    },
    node_detail_modal(node_id) {
      this.selected_node_id = node_id
      useJwt.companyOrganizationNodesDetail(this.selected_node_id)
        .then(response => {
          this.selected_node.data = response.data

          this.qr_code_url = process.env.VUE_APP_PORTAL_URL+'node_query?url_token=' + this.selected_node.data.url_token

          // todo admin yetkisi ayarlanacak Erişim yetkisi olan nodelar için buton yetkisi ayarlanıyor
          if (this.customer_is_admin == true) {
            this.survey_tab_show = true
            this.qrcode_tab_show = true
            this.feedback_tab_show = true
            this.user_management_tab_show = true
          } else {
            this.pp.forEach(p => {
              p['child_nodes'].forEach(child => {
                if (child == node_id) {
                  if (p['node']['node_create'] == true) {
                    this.survey_tab_show = true
                  }
                  if (p['node']['qrcode_read'] == true) {
                    this.qrcode_tab_show = true
                  }
                  if (p['node']['feedback_read'] == true) {
                    this.feedback_tab_show = true
                  }
                  if (p['node']['user_read'] == true) {
                    this.user_management_tab_show = true
                  }
                }
              })
            })
          }

          this.$refs.view_record_modal_selected_noda.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>
