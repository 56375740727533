var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"add_sub_node_modal",attrs:{"id":"add_sub_node_modal","centered":"","no-close-on-backdrop":"","content-class":"shadow","footer-class":"d-none","title":((_vm.selected_node.data.name) + " | " + (_vm.$t('add_new_node_modal.title_node_add'))),"size":"lg"},on:{"hide":_vm.close_add_sub_node_modal}},[_c('validation-observer',{ref:"addForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.add_sub_node_button($event)}}},[_c('div',{staticStyle:{"min-height":"600px!important"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',[_c('label',{attrs:{"for":"i-plan"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('add_new_node_modal.node_type')))]),_c('validation-provider',{attrs:{"name":"i-plan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"i-plan","state":errors.length > 0 ? false: _vm.temp_sub_node_type.organization_type_code == null ? true : null,"options":_vm.organization_node_list,"selectable":function (option) { return !option.organization_type_code.includes('main'); },"label":"name","clearable":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.temp_sub_node_type),callback:function ($$v) {_vm.temp_sub_node_type=$$v},expression:"temp_sub_node_type"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.temp_sub_node_type.organization_type_code != null),expression:"temp_sub_node_type.organization_type_code != null"}],attrs:{"cols":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"sb-maxmin"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('add_new_node_modal.node_count')))]),_c('validation-provider',{attrs:{"name":"sb-maxmin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-spinbutton',{attrs:{"id":"sb-maxmin","min":"1","max":"100","state":errors.length > 0 ? false: _vm.node_count > 0 ? true : false},on:{"change":_vm.onSpinbuttonChange},model:{value:(_vm.node_count),callback:function ($$v) {_vm.node_count=$$v},expression:"node_count"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('div',{staticStyle:{"overflow-y":"auto","height":"500px"}},_vm._l((_vm.new_node_list),function(node,index){return _c('b-row',{key:index},[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"7"}},[_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(index +1)+". "+_vm._s(_vm.temp_sub_node_type.name))]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"name","state":errors.length > 0 ? false: node['node_name'] != null ? true : null,"placeholder":((index+1) + " " + (_vm.temp_sub_node_type.name))},model:{value:(node['node_name']),callback:function ($$v) {_vm.$set(node, 'node_name', $$v)},expression:"node['node_name']"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"5"}},[_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('add_new_node_modal.node_color')))]),_c('color-picker',{attrs:{"no-input":"","color":node['node_color'],"picker":"sketch"},model:{value:(node['node_color']),callback:function ($$v) {_vm.$set(node, 'node_color', $$v)},expression:"node['node_color']"}})],1)],1)],1)}),1),_c('b-row',[_c('b-col',{staticClass:"justify-content-end text-right"},[_c('hr'),_c('b-button',{attrs:{"variant":"primary","size":"sm","type":"submit","disabled":invalid}},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('core.btn_add')))])])],1)],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }