<template>
  <div>
    <!--    Qr Code Download Helper Modal-->
    <b-modal
      id="qrcode_download_helper"
      ref="qrcode_download_helper"
      centered
      no-close-on-backdrop
      content-class="shadow"
      :title="$t('qrcode_download_helper.btn_qrcode_download_helper')"
      footer-class="d-none"
      size="xl"
      @hide="close_qrcode_download_helper"
    >
      <b-row class="justify-content-center">
        <b-col
          v-if="preparation_status == false"
          cols="4"
          class="border"
        >
          <validation-observer
            ref="insertSurveyGroupForm"
            #default="{invalid}"
          >
            <b-form @submit.prevent="qrcode_download_helper_button">
              <b-row>
                <b-col
                  cols="12"
                  class="mb-md-0 mb-2"
                >
                  <b-form-group>
                    <label for="name"><span class="text-danger text-bold">*</span> {{ $t('qrcode_download_helper.sub_nodes_insert') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="sub_nodes_insert"
                      rules="required"
                    >
                      <v-select
                        id="survey_group"
                        v-model="sub_nodes_insert"
                        :options="sub_nodes_insert_options"
                        :state="errors.length > 0 ? false: sub_nodes_insert != null ? true : null"
                        label="title"
                        :clearable="false"
                        class="mx-25"
                        :placeholder="$t('qrcode_download_helper.sub_nodes_insert')"
                      >
                        <template #option="{ title, icon }" />
                      </v-select>
                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >{{ $t('core.required') }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  class="mb-md-0 mb-2"
                >
                  <b-form-group>
                    <label for="name"><span class="text-danger text-bold">*</span> {{ $t('qrcode_download_helper.export_render_method') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="export_render_method"
                      rules="required"
                    >
                      <v-select
                        id="export_render_method"
                        v-model="export_render_method"
                        :options="export_render_method_options"
                        :state="errors.length > 0 ? false: export_render_method != null ? true : null"
                        label="title"
                        :clearable="false"
                        class="mx-25"
                        :placeholder="$t('qrcode_download_helper.export_render_method')"
                      >
                        <template #option="{ title, icon }" />
                      </v-select>
                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >{{ $t('core.required') }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                v-if="sub_nodes_insert.value==true&&export_render_method.value=='custom_design'"
              >
                <b-col cols="12">
                  <hr>
                </b-col>
                <b-col
                  cols="4"
                >
                  <b-form-group>
                    <label for="name"><span class="text-danger text-bold">*</span> {{ $t('qrcode_download_helper.marker_location') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="marker_location"
                      rules="required"
                    >
                      <v-select
                        id="marker_location"
                        v-model="marker_location"
                        :options="marker_location_options"
                        :state="errors.length > 0 ? false: marker_location != null ? true : null"
                        label="title"
                        :clearable="false"
                        class="mx-25"
                        :placeholder="$t('qrcode_download_helper.marker_location')"
                      >
                        <template #option="{ title, icon }" />
                      </v-select>
                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >{{ $t('core.required') }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="4"
                >
                  <b-form-group>
                    <label for="name"><span class="text-danger text-bold">*</span> {{ $t('qrcode_download_helper.marker_size') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="marker_size"
                      rules="required"
                    >
                      <b-form-input
                        id="marker_size"
                        v-model="marker_size"
                        size="md"
                        :placeholder="$t('qrcode_download_helper.marker_size')"
                      />
                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >{{ $t('core.required') }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="4"
                >
                  <b-form-group>
                    <label for="name"><span class="text-danger text-bold">*</span> {{ $t('qrcode_download_helper.marker_color') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="marker_color"
                      rules="required"
                    >
                      <color-picker
                        v-model="marker_color"
                        :color="marker_color"
                        no-input
                        picker="compact"
                      />
                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >{{ $t('core.required') }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <div style="font-size: 10px">
                    <u>{{$t('qrcode_download_helper.indicator')}}:</u> {{$t('qrcode_download_helper.indicator_text')}}
                  </div>
                </b-col>
              </b-row>
              <hr>
              <b-row class="justify-content-end">
                <b-button
                  size="sm"
                  variant="primary"
                  class="mr-1"
                  @click="export_btn"
                >
                  <span class="align-middle">{{ $t('qrcode_download_helper.download') }} <i class="pi pi-download" /></span>
                </b-button>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
        <b-col
          cols="6"
          class="d-flex justify-content-center"
        >
          <b-row class="justify-content-center text-center">
            <b-col
              v-if="preparation_status == true"
              cols="12"
              class="mb-25"
            >
              <div class="progress-wrapper">
                <b-card-text class="mb-0">
                  <div v-if="curently_counter!=total_qrcode_design">
                    {{$t('qrcode_download_helper.design_rendering')}} ( {{ curently_counter }} / {{ total_qrcode_design }})
                  </div>
                  <div v-else>
                    {{$t('qrcode_download_helper.design_rendered')}}
                  </div>
                </b-card-text>
                <b-progress
                  v-model="curently_counter"
                  class="progress-bar-warning"
                  :max="total_qrcode_design"
                />
              </div>
            </b-col>
            <b-col cols="12">
              <img
                v-if="previewImage"
                class="border border-dark"
                style="max-height: 500px"
                :src="previewImage"
                alt="Canvas Preview"
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
    <!--    Qr Code Download Helper Modal-->
  </div>
</template>
<script>
import {
  BButton, BCardText,
  BCol, BForm, BFormGroup, BFormInput, BModal, BProgress,
  BRow,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ColourPicker from 'vue-colour-picker'
import QrCodeStyling from 'qr-code-styling'
import path from '@/assets/images/logo/geribildir.io.png'
import useJwt from '@/auth/jwt/useJwt'
import { fabric } from 'fabric'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { getUserData } from '@/auth/utils'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    'color-picker': ColourPicker,
    BForm,
    BProgress,
    BFormInput,
    ValidationProvider,
    BButton,
    BModal,
    vSelect,
    BFormGroup,
    BCol,
    BRow,
    BCardText,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    selected_node: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      previewImage: null,
      canvas: null,
      qrcode: null,
      base64: null,
      temp_qrcode_data: null,
      sub_nodes_insert: { title: this.$t('qrcode_download_helper.no'), value: false },
      sub_nodes_insert_options: [
        { title: this.$t('qrcode_download_helper.yes'), value: true },
        { title: this.$t('qrcode_download_helper.no'), value: false },
      ],
      export_render_method: { title: this.$t('qrcode_download_helper.custom_design'), value: 'custom_design' },
      export_render_method_options: [
        { title: this.$t('qrcode_download_helper.custom_design'), value: 'custom_design' },
        { title: this.$t('qrcode_download_helper.qr_code'), value: 'qr_code' },
      ],
      marker_location: { title: this.$t('qrcode_download_helper.bottom_right'), value: 'bottom-right' },
      marker_location_options: [
        { title: this.$t('qrcode_download_helper.top_left'), value: 'top-left' },
        { title: this.$t('qrcode_download_helper.top_right'), value: 'top-right' },
        { title: this.$t('qrcode_download_helper.bottom_left'), value: 'bottom-left' },
        { title: this.$t('qrcode_download_helper.bottom_right'), value: 'bottom-right' },
      ],
      marker_size: 200,
      marker_color: '#fffff',
      qrcode_meta: null,
      qrcode_url: null,
      canvases: [],
      temp_node_data: [],
      total_qrcode_design: 0,
      curently_counter: 0,
      preparation_status: false,
    }
  },
  watch: {
    sub_nodes_insert() { this.canvas_render() },
    export_render_method() { this.canvas_render() },
    marker_location() { this.canvas_render() },
    marker_size() { this.canvas_render() },
    marker_color() { this.canvas_render() },
  },
  created() {
    this.check_qrcode_status()
  },
  mounted() {
  },
  methods: {
    get_file_content() {
      const dizi1 = [
        ['#', this.$t('qrcode_download_helper.node_name'), this.$t('qrcode_download_helper.parent_node')],
      ]
      this.temp_node_data.forEach(node => { dizi1.push([node.counter, node.node_name, node.parent_nodes]) })

      // Her sütun için maksimum genişliği belirleyin
      const colWidths = [0, 0, 0]
      dizi1.forEach(row => {
        row.forEach((cell, i) => {
          colWidths[i] = Math.max(colWidths[i], String(cell).length)
        })
      })
      // Sınır çizgileri ve tablo başlığı ekleyin
      const horizontalBorder = `+${colWidths.map(width => '-'.repeat(width + 2)).join('+')}+`

      // Verileri hizalı bir şekilde tablo formatına çevirin
      let formattedData = `${horizontalBorder}\n`

      dizi1.forEach((row, rowIndex) => {
        const formattedRow = row.map((cell, i) => ` ${String(cell).padEnd(colWidths[i])} `).join('|')
        formattedData += `|${formattedRow}|\n`
        if (rowIndex === 0) {
          // Başlık satırının altına çizgi ekleyin
          formattedData += `${horizontalBorder}\n`
        }
      })
      formattedData += `${horizontalBorder}\n`

      return formattedData
    },
    saveAllAsZIP() {
      const zip = new JSZip()

      this.canvases.forEach((rrr, index) => {
        const dataURL = rrr.canvas_data.toDataURL({
          format: 'png',
          quality: 1.0,
        })
        zip.file(`${rrr.file_name}.png`, dataURL.split(',')[1], { base64: true })
      })

      zip.file('map.txt', this.get_file_content())

      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, 'geribildir_io.zip')
      })
    },
    export_qrcode_generator(value, qrcode_meta) {
      const path = require('@/assets/images/logo/geribildir.io.png')
      const qrcode = new QrCodeStyling({
        width: 5000,
        height: 5000,
        data: value,
        image: path,
        qrOptions: {
          errorCorrectionLevel: qrcode_meta.extension.errorCorrectionLevel,
        },
        dotsOptions: {
          color: qrcode_meta.extension.dotsColor,
          type: qrcode_meta.extension.dotsType,
        },
        cornersSquareOptions: {
          color: qrcode_meta.extension.cornersSquareColor,
          type: qrcode_meta.extension.cornersSquareType,
        },
        cornersDotOptions: {
          color: qrcode_meta.extension.cornersDotColor,
          type: qrcode_meta.extension.cornersDotType,
        },
        backgroundOptions: {
          color: qrcode_meta.extension.background,
        },
        imageOptions: {
          margin: 0,
        },
      })
      return qrcode
    },
    async getQRCodeDataURL2(value, qrcode_meta) {
      const path = require('@/assets/images/logo/geribildir.io.png')
      this.qrCode = new QrCodeStyling({
        width: qrcode_meta.width,
        height: qrcode_meta.height,
        data: value,
        image: path,
        qrOptions: {
          errorCorrectionLevel: qrcode_meta.extension.errorCorrectionLevel,
        },
        dotsOptions: {
          color: qrcode_meta.extension.dotsColor,
          type: qrcode_meta.extension.dotsType,
        },
        cornersSquareOptions: {
          color: qrcode_meta.extension.cornersSquareColor,
          type: qrcode_meta.extension.cornersSquareType,
        },
        cornersDotOptions: {
          color: qrcode_meta.extension.cornersDotColor,
          type: qrcode_meta.extension.cornersDotType,
        },
        backgroundOptions: {
          color: qrcode_meta.extension.background,
        },
        imageOptions: {
          margin: 0,
        },
      })
      const ss = await this.qrCode.getRawData('png').then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })).catch(error => {
        console.error('Error:', error)
      })
      return ss
    },
    check_qrcode_status() {
      this.qrcode = null
      if (this.selected_node.data.qrcode_is_setup === true) {
        if (this.selected_node.data.qrcode_type === 'one') {
          useJwt.getNodeQRCodeList({ node: this.selected_node.data.id })
            .then(async response => {
              // eslint-disable-next-line prefer-destructuring
              this.temp_qrcode_data = response.data.results[0]
              for (const obj of this.temp_qrcode_data.qrcode_template_json.objects) {
                if (obj.extensionType && obj.extensionType == 'qrcode') {
                  this.qrcode_meta = obj
                  this.qrcode_url = `${process.env.VUE_APP_PORTAL_URL}node_query?url_token=${this.selected_node.data.url_token}`
                  const mmm = await this.getQRCodeDataURL2(this.qrcode_url, obj)
                  obj.src = mmm
                }
              }
              this.canvas_render()
            })
            .catch(error => {
              console.log(error)
            })
        }
        if (this.selected_node.data.qrcode_type === 'group') {
          useJwt.getQRCodeGroupNodesList({ node: this.selected_node.data.id })
            .then(async response => {
              // eslint-disable-next-line prefer-destructuring
              this.temp_qrcode_data = response.data.results[0].group
              for (const obj of this.temp_qrcode_data.qrcode_template_json.objects) {
                if (obj.extensionType && obj.extensionType == 'qrcode') {
                  this.qrcode_meta = obj
                  this.qrcode_url = `${process.env.VUE_APP_PORTAL_URL}node_query?url_token=${this.selected_node.data.url_token}`
                  const mmm = await this.getQRCodeDataURL2(this.qrcode_url, obj)
                  obj.src = mmm
                }
              }
              this.canvas_render()
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    },
    addSerialNumber(canvas, number, position) {
      const text = new fabric.Text(number.toString(), {
        fontSize: this.marker_size,
        fill: this.marker_color,
        fontWeight: 'bold',
      })

      switch (position) {
        case 'top-left':
          text.set({ left: 10, top: 10 })
          break
        case 'top-right':
          text.set({ left: canvas.width - text.width - 10, top: 10 })
          break
        case 'bottom-left':
          text.set({ left: 10, top: canvas.height - text.height - 10 })
          break
        case 'bottom-right':
          text.set({ left: canvas.width - text.width - 10, top: canvas.height - text.height - 10 })
          break
        default:
          break
      }

      canvas.add(text)
      canvas.renderAll()
    },
    canvas_render() {
      this.canvas = new fabric.Canvas('', {
        width: this.temp_qrcode_data.qrcode_template_json.clipPath.width,
        height: this.temp_qrcode_data.qrcode_template_json.clipPath.height,
        backgroundColor: this.temp_qrcode_data.qrcode_template_json.clipPath.fill,
      })
      this.canvas.loadFromJSON(this.temp_qrcode_data.qrcode_template_json, (ee => {
        if (this.sub_nodes_insert.value == true && this.export_render_method.value == 'custom_design') {
          this.addSerialNumber(this.canvas, 1, this.marker_location.value)
          this.canvas.renderAll.bind(this.canvas)
        } else {
          this.canvas.renderAll.bind(this.canvas)
        }

        this.previewImage = this.canvas.toDataURL()
      }))
    },
    export_btn() {
      if (this.sub_nodes_insert.value == false) {
        if (this.export_render_method.value == 'custom_design') {
          const dataURL = this.canvas.toDataURL({
            format: 'png',
            quality: 1.0,
          })
          const link = document.createElement('a')
          link.href = dataURL
          link.download = `${this.selected_node.data.name} - qrcode_design.png`
          link.click()
        } else {
          const qrcode = this.export_qrcode_generator(this.qrcode_url, this.qrcode_meta)
          qrcode.download({ name: `${this.selected_node.data.name} - only_qrcode`, extension: 'png' })
        }
      } else {
        // çoklu indirme özelliği eklenecek
        const formData = new FormData()
        formData.append('node', this.selected_node.data.id)
        useJwt.getSubNodeQrcodeData(formData)
          .then(async response => {
            const { nodes } = response.data
            this.temp_node_data = nodes
            this.total_qrcode_design = nodes.length
            this.preparation_status = true
            if (this.export_render_method.value == 'custom_design') {
              for (const node of nodes) {
                this.curently_counter += 1
                for (const obj of node.qrcode_template.objects) {
                  if (obj.extensionType && obj.extensionType == 'qrcode') {
                    const qrcode_url = `${process.env.VUE_APP_PORTAL_URL}node_query?url_token=${node.node_url_token}`
                    obj.src = await this.getQRCodeDataURL2(qrcode_url, obj)
                  }
                }
                const canvas = new fabric.Canvas('', {
                  width: node.qrcode_template.clipPath.width,
                  height: node.qrcode_template.clipPath.height,
                  backgroundColor: node.qrcode_template.clipPath.fill,
                })
                await new Promise(resolve => { canvas.loadFromJSON(node.qrcode_template, resolve) })
                this.addSerialNumber(canvas, node.counter, this.marker_location.value)
                await canvas.renderAll.bind(canvas)
                this.previewImage = canvas.toDataURL()
                this.canvases.push({ file_name: node.counter, canvas_data: canvas })
              }
              this.saveAllAsZIP()
            } else {
              const qrcode_list = []
              const zip = new JSZip()
              for (const node of nodes) {
                this.curently_counter += 1
                const qrcode_url = `${process.env.VUE_APP_PORTAL_URL}node_query?url_token=${node.node_url_token}`
                let qrcode_meta = null
                for (const obj of node.qrcode_template.objects) {
                  if (obj.extensionType && obj.extensionType == 'qrcode') {
                    qrcode_meta = obj
                  }
                }
                const qrcode = this.export_qrcode_generator(qrcode_url, this.qrcode_meta)
                const blob = new Promise(resolve => { qrcode.getRawData('png').then(blob => { resolve(blob) }) })
                zip.file(`${node.counter}.png`, blob)
              }
              zip.file('map.txt', this.get_file_content())
              zip.generateAsync({ type: 'blob' }).then(content => {
                saveAs(content, 'geribildir_io.zip')
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    close_qrcode_download_helper() {
      this.$refs.qrcode_download_helper.hide()
      this.sub_nodes_insert = { title: this.$t('qrcode_download_helper.no'), value: false }
      this.export_render_method = { title: this.$t('qrcode_download_helper.custom_design'), value: 'custom_design' }
    },
  },
}
</script>
<style>

</style>
