<template>
  <div>
    <feedback-view-modal :organization_node_type_options="organization_node_type_options" :update_data_table="getTableData" />
    <b-row
      class="mx-1"
      align-h="between"
      align-v="center"
      v-if="view_mode!='dashboard'"
    >
      <div class="d-flex">
        <!--            <v-select-->
        <!--                size="sm"-->
        <!--                v-model="perPage"-->
        <!--                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--                :options="perPageOptions"-->
        <!--                :clearable="false"-->
        <!--                class="per-page-selector d-inline-block mx-50"-->
        <!--            />-->
        <b-form-group>
          <v-select
            id="i-plan"
            v-model="feedback_status"
            :options="feedback_status_options"
            label="title"
            :clearable="false"
            :placeholder="$t('feedback_management.feedback_status')"
            style="min-width: 200px"
            multiple
          >
            <template #option="{ title, icon }" />
          </v-select>
        </b-form-group>
        <b-form-group
          class="ml-50"
        >
          <v-select
            id="i-plan"
            v-model="priority_status"
            :options="priority_status_options"
            label="title"
            :clearable="false"
            :placeholder="$t('feedback_management.priority_status')"
            style="min-width: 200px"
            multiple
          >
            <template #option="{ title, icon }" />
          </v-select>
        </b-form-group>
        <b-form-group class="ml-50">
          <v-select
            id="i-plan"
            style="min-width: 200px"
            v-model="organization_node_type"
            :options="organization_node_type_options"
            label="name"
            :clearable="false"
            :placeholder="$t('feedback_management.node_type')"
            multiple
          >
            <template #option="{ title, icon }" />
          </v-select>
        </b-form-group>
        <b-form-group class="ml-50">
          <b-input-group>
            <b-input-group-prepend is-text>
              <i class="pi pi-calendar"></i>
            </b-input-group-prepend>
            <date-range-picker
                ref="picker"
                v-model="orderRangeDate"
                :placeholder="$t('feedback_management.created_feedback_datetime')"
                style="min-width: 200px"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          v-if="selected_node!=undefined"
          class="my-auto"
        >
          <b-form-checkbox
            id="register-privacy-policy"
            v-model="sub_node_list"
            :value="true"
            :unchecked-value="false"
            switch
            name="checkbox-1"
          >{{$t('feedback_management.feedback_filter_insert_sub_node')}}
          </b-form-checkbox>

        </b-form-group>
      </div>
      <div>
        <b-form-input
          v-model="searchQuery"
          size="md"
          class="d-inline-block mr-1"
          :placeholder="$t('core.search')"
        />

      </div>
    </b-row>
    <b-row class="mt-50 mx-0">
      <b-table
        responsive
        hover
        :items="table_data"
        :fields="tableColumns"
        primary-key="id"
        class="text-nowrap"
        show-empty
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :empty-text="$t('core.no_records_found')"
        striped
        :tbody-tr-class="getRowClass"
        @row-clicked="onRowClicked"
        :small="view_mode=='dashboard' ? 'small': false"
      >
        <!-- Column: feedback_status -->
        <template #cell(node.node_type_code)="data">
          <span
            v-for="item in organization_node_type_options"
            v-show="item.organization_type_code==data.item.node.node_type_code"
            :key="item.organization_type_code"
          >{{ item.name }}</span>
        </template>

        <!-- Column: feedback_status -->
        <template #cell(feedback_status)="data">
          <b-badge
            v-if="data.item.feedback_status=='new'"
            variant="primary"
          >
            <feather-icon icon="GiftIcon" /> {{ $t("feedback_management.feedback_status_options_new") }}
          </b-badge>
          <b-badge
            v-if="data.item.feedback_status=='inprocessing'"
            variant="danger"
          >
            <feather-icon icon="CoffeeIcon" /> {{ $t("feedback_management.feedback_status_options_inprocessing") }}
          </b-badge>
          <b-badge
            v-if="data.item.feedback_status=='completed'"
            variant="success"
          >
            <feather-icon icon="CheckIcon" /> {{ $t("feedback_management.feedback_status_options_completed") }}
          </b-badge>
          <b-badge
            v-if="data.item.feedback_status=='cancelled'"
            variant="secondary"
          >
            <feather-icon icon="XIcon" /> {{ $t("feedback_management.feedback_status_options_cancelled") }}
          </b-badge>
        </template>

        <!-- Column: priority_status -->
        <template #cell(priority_status)="data">
          <b-badge
            v-if="data.item.priority_status=='low'"
            variant="success"
          >
            {{ $t("feedback_management.priority_status_options_low") }}
          </b-badge>
          <b-badge
            v-if="data.item.priority_status=='normal'"
            variant="secondary"
          >
            {{ $t("feedback_management.priority_status_options_normal") }}
          </b-badge>
          <b-badge
            v-if="data.item.priority_status=='high'"
            variant="danger"
          >
            {{ $t("feedback_management.priority_status_options_high") }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="rowUpdateModal(data.item.id, )">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t("core.btn_update") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="rowDeleteModal(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("core.btn_delete") }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$can('manage', 'user_management')&&data.item.role=='user'"
                @click="rowGivePermissionModal(data.item.id)"
              >
                <feather-icon icon="StarIcon" />
                <span class="align-middle ml-50">{{ $t("user_management.modal_titile_set_admin_privilege") }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$can('manage', 'user_management')&&data.item.role=='admin'"
                @click="rowRevokePermissionModal(data.item.id)"
              >
                <feather-icon icon="UserXIcon" />
                <span class="align-middle ml-50">{{ $t("user_management.modal_titile_delete_admin_privilege") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
      </b-col>
      <!-- Pagination -->
      <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import Board from '@salamander.be/vue-kanban-board'
import '@salamander.be/vue-kanban-board/dist/vue-kanban-board.css'
import {
  BBadge, BBreadcrumbItem,
  BButton, BButtonGroup,
  BCol,
  BDropdown,
  BDropdownItem, BFormCheckbox, BFormGroup,
  BFormInput, BInputGroup, BInputGroupPrepend,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import FeedbackViewModal from '@/views/custom_app/client/feedback_management/components/FeedbackViewModal.vue'
import flatPickr from 'vue-flatpickr-component'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { lineChartOptions } from '@core/components/statistics-cards/chartOptions'

export default {
  components: {
    BFormCheckbox,
    BBreadcrumbItem,
    BInputGroup,
    BInputGroupPrepend,
    FeedbackViewModal,
    BFormGroup,
    BFormInput,
    BPagination,
    vSelect,
    BButton,
    BTable,
    BRow,
    BCol,
    BDropdown,
    BBadge,
    BDropdownItem,
    BTab,
    BTabs,
    Board,
    BButtonGroup,
    flatPickr,
    DateRangePicker,
  },
  props: {
    selected_node: {
      type: Object,
      required: false,
    },
    view_mode: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      view_type: null,
      selected_row_name: null,
      selected_row_id: null,
      sub_node_list: false,
      perPageOptions: [25, 50, 100],
      searchQuery: null,
      sortBy: null,
      isSortDirDesc: null,
      table_data: null,
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      orderRangeDate: {
        // startDate: new Date(),
        // endDate: new Date(),
      },
      organization_node_type: [],
      organization_node_type_options: [],
      tableColumns: [
        { key: 'node.name', label: this.$t('feedback_management.node_name'), sortable: true },
        { key: 'node.node_type_code', label: this.$t('feedback_management.node_type'), sortable: true },
        { key: 'feedback_status', label: this.$t('feedback_management.feedback_status'), sortable: true },
        { key: 'priority_status', label: this.$t('feedback_management.priority_status'), sortable: true },
        { key: 'created', label: this.$t('feedback_management.created_feedback_datetime'), sortable: true },
        { key: 'closed_time', label: this.$t('feedback_management.closed_time_feedback_datetime'), sortable: true },
        { key: 'associated_user', label: this.$t('feedback_management.associated_user'), sortable: true },
        // { key: 'id', label: '#' },
      ],
      feedback_status: [
        { title: this.$t('feedback_management.feedback_status_options_new'), value: 'new' },
        { title: this.$t('feedback_management.feedback_status_options_inprocessing'), value: 'inprocessing' },
      ],
      feedback_status_options: [
        { title: this.$t('feedback_management.feedback_status_options_new'), value: 'new' },
        { title: this.$t('feedback_management.feedback_status_options_inprocessing'), value: 'inprocessing' },
        { title: this.$t('feedback_management.feedback_status_options_completed'), value: 'completed' },
        { title: this.$t('feedback_management.feedback_status_options_cancelled'), value: 'cancelled' },
      ],
      priority_status: [],
      priority_status_options: [
        { title: this.$t('feedback_management.priority_status_options_low'), value: 'low' },
        { title: this.$t('feedback_management.priority_status_options_normal'), value: 'normal' },
        { title: this.$t('feedback_management.priority_status_options_high'), value: 'high' },
      ],
    }
  },
  watch: {
    sortBy: { handler() { this.getTableData() } },
    sub_node_list: { handler() { this.getTableData() } },
    feedback_status: { handler() { this.getTableData() } },
    priority_status: { handler() { this.getTableData() } },
    organization_node_type: { handler() { this.getTableData() } },
    isSortDirDesc: { handler() { this.getTableData() } },
    orderRangeDate: { handler() { this.getTableData() } },
    currentPage: {
      handler() {
        this.getTableData()
      },
    },
    searchQuery: {
      handler() {
        this.getTableData()
      },
    },
  },
  created() {
    if (this.view_mode=='node' && this.selected_node) {
      this.view_type = 'node'
      this.selected_row_id = this.selected_node.data.id
    } else if (this.view_mode=='dashboard'){
      this.view_type = 'full'
      this.tableColumns = [
        { key: 'node.name', label: this.$t('feedback_management.node_name'), sortable: true },
        { key: 'node.node_type_code', label: this.$t('feedback_management.node_type'), sortable: true },
        { key: 'feedback_status', label: this.$t('feedback_management.feedback_status'), sortable: true },
        { key: 'priority_status', label: this.$t('feedback_management.priority_status'), sortable: true },
        { key: 'created', label: this.$t('feedback_management.created_feedback_datetime'), sortable: true },
      ]
    } else {
      this.view_type = 'full'
    }
    this.getOrganizationNodes()
    this.getTableData()
  },
  methods: {
    getOrganizationNodes() {
      useJwt.getOrganizationTypeList()
        .then(response => {
          this.organization_node_type_options = response.data.organization_type_list
          this.organization_node_type_options.sort((a, b) => ((a.name > b.name) ? 1 : -1))
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    getRowClass(row) {
      if (row != null) {
        if (row.is_read === false) {
          return 'bg-light-warning'
        }
        return ''
      }
    },
    getTableData() {
      const filtred_feedback_status_list = []
      const filtred_priority_status_list = []
      const filtred_organization_node_type = []
      this.feedback_status.forEach(item => { filtred_feedback_status_list.push(item.value) })
      this.priority_status.forEach(item => { filtred_priority_status_list.push(item.value) })
      this.organization_node_type.forEach(item => { filtred_organization_node_type.push(item.organization_type_code) })
      useJwt.getFeedbackList({
        // center_type: this.filter_center_type.value,
        view_type: this.view_type,
        node_id: this.selected_row_id,
        sub_node_list: this.sub_node_list,
        page: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        feedback_status__in: filtred_feedback_status_list.join(', '),
        priority_status__in: filtred_priority_status_list.join(', '),
        node__node_type_code__in: filtred_organization_node_type.join(', '),
        created__gte: this.orderRangeDate.startDate,
        created__lte: this.orderRangeDate.endDate,
      })
        .then(response => {
          this.table_data = response.data.results
          this.totalRows = response.data.count
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    onRowClicked(row) {
      window.node_add_modal222(row.id)
    },
  },
}
</script>
<style lang="scss">
.table-hover tbody tr:hover {
  background-color: #f5f5f5;
}
</style>
