<template>
  <div>
    <!--    View Modal-->
    <b-modal
      id="new_node_append_modal"
      ref="new_node_append_modal"
      centered
      no-close-on-backdrop
      content-class="shadow"
      :title="$t('feedback_view_modal.modal_title')"
      footer-class="d-none"
      size="xl"
      @hide="close_new_node_append_modal"
    >
      <b-row>
        <b-col
          v-if="iframeSrc!=null"
          cols="6"
          style="overflow-y: auto; height:600px;"
        >
<!--          <survey :survey="survey" />-->
          <iframe
              :src="iframeSrc"
              width="600"
              height="600"
              frameborder="0"
              allowfullscreen
          />
        </b-col>
        <b-col cols="6">
          <b-row>
            <b-col cols="12">{{full_path}} <hr></b-col>
            <b-col
              xl="6"
              cols="12"
              class="p-0 mt-xl-0 mt-2 d-flex "
            >
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td class="pr-1">{{$t('feedback_view_modal.node_name')}}:</td>
                      <td v-if="selected_node!=null"><span class="font-weight-bold">{{ selected_node.node.name }}</span></td>
                    </tr>
                    <tr>
                      <td class="pr-1">{{$t('feedback_view_modal.feedback_time')}}:</td>
                      <td v-if="selected_node!=null">{{ selected_node.created }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        {{$t('feedback_view_modal.feedback_read_time')}}:
                      </td>
                      <td v-if="selected_node!=null">
                        {{ selected_node.reading_time }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        {{$t('feedback_view_modal.status')}}:
                      </td>
                      <td v-if="selected_node!=null">
                        <v-select
                          id="i-plan"
                          v-model="feedback_status"
                          :options="feedback_status_options"
                          label="title"
                          :clearable="false"
                        >
                          <template #option="{ title, icon }" />
                        </v-select>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        {{$t('feedback_view_modal.related_user')}}:
                      </td>
                      <td v-if="selected_node!=null">
                        <v-select
                          id="i-plan"
                          v-model="priority_status"
                          :options="priority_status_options"
                          label="title"
                          :clearable="false"
                        >
                          <template #option="{ title, icon }" />
                        </v-select>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        {{ $t('feedback_view_modal.priority')}}:
                      </td>
                      <td v-if="selected_node!=null">
                        <v-select
                          id="i-plan"
                          v-model="priority_status"
                          :options="priority_status_options"
                          label="title"
                          :clearable="false"
                        >
                          <template #option="{ title, icon }" />
                        </v-select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col
              xl="6"
              cols="12"
              class="p-0 mt-xl-0 mt-2 d-flex "
            >
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td class="pr-1">
                        {{ $t('feedback_view_modal.node_type') }}:
                      </td>
                      <td v-if="selected_node!=null">
                        <span
                            class="font-weight-bold"
                            v-for="item in organization_node_type_options"
                            v-show="item.organization_type_code==selected_node.node.node_type_code"
                            :key="item.organization_type_code"
                        >{{ item.name }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        {{ $t('feedback_view_modal.closed_feedback_time') }}:
                      </td>
                      <td v-if="selected_node!=null">
                        {{ selected_node.closed_time }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <tr class="w-100">
              <td class="pr-1">
                {{ $t('feedback_view_modal.tag') }}:
              </td>
              <td class="w-100">
                <v-select
                  id="i-plan"
                  v-model="tag_list"
                  class="w-100"
                  :options="tag_list_options"
                  label="title"
                  multiple
                  taggable
                >

                  >
                </v-select>
              </td>
            </tr>
          </b-row>

          <hr>
          <!-- Yorum Taarfı          -->
          <b-row v-if="comment_input_box_is_visible">
            <b-col cols="12">
              <b-form-textarea
                id="textarea-default"
                v-model="comment_box"
                :placeholder="$t('feedback_view_modal.comment')"
                rows="3"
              />
            </b-col>
          </b-row>
          <b-row class="justify-content-end mt-25">
            <div>
              <b-button
                size="sm"
                variant="primary"
                @click="addCommentBtn"
              >
                <span class="text-nowrap">{{$t('feedback_view_modal.comment_add')}}</span>
              </b-button>
            </div>
          </b-row>
          <hr>
          <div style="overflow-y: auto; height:300px;">
            <b-row
              v-for="(comment, index) in comment_list"
              :key="index"
            >
              <b-col cols="12">
                <b-alert
                  variant="primary"
                  show
                >
                  <div class="alert-heading">
                    <div class="d-flex justify-content-between">
                      <div>{{ comment.company_user.user.name }}</div>
                      <div>{{ comment.created }}</div>
                    </div>
                  </div>
                  <div class="alert-body">
                    <span>{{ comment.comment }}</span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>
          </div>
          <!-- Yorum Taarfı          -->

        </b-col>
      </b-row>
    </b-modal>
    <!--    Ekle Modal-->
  </div>
</template>
<script>
import {
  BAlert,
  BButton, BCardText, BCol, BFormGroup, BFormInput, BFormTextarea,
  BInputGroup, BInputGroupPrepend, BModal, BNavItem, BRow,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { Model } from 'survey-core'
import 'survey-core/defaultV2.css'
import { Survey } from 'survey-vue-ui'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BNavItem,
    BFormGroup,
    vSelect,
    Survey,
    BInputGroupPrepend,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BCardText,
    BModal,
    BInputGroup,
    BFormTextarea,
    BAlert,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    update_data_table: {
      type: Function,
      required: true,
    },
    organization_node_type_options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      iframeSrc: null,
      full_path: null,
      selected_row_id: null,
      selected_node: null,
      survey: null,
      feedback_status: {},
      feedback_status_options: [
        { title: this.$t('feedback_management.feedback_status_options_new'), value: 'new' },
        { title: this.$t('feedback_management.feedback_status_options_inprocessing'), value: 'inprocessing' },
        { title: this.$t('feedback_management.feedback_status_options_completed'), value: 'completed' },
        { title: this.$t('feedback_management.feedback_status_options_cancelled'), value: 'cancelled' },
      ],
      priority_status: {},
      priority_status_options: [
        { title: this.$t('feedback_management.priority_status_options_low'), value: 'low' },
        { title: this.$t('feedback_management.priority_status_options_normal'), value: 'normal' },
        { title: this.$t('feedback_management.priority_status_options_high'), value: 'high' },
      ],
      comment_input_box_is_visible: false,
      comment_box: null,
      comment_list: [],
      tag_list: [],
      tag_list_options: [],
    }
  },
  watch: {
    feedback_status: { handler() { this.update_feedback_data() } },
    priority_status: { handler() { this.update_feedback_data() } },
    tag_list: { handler() { this.updateTags() } },
  },
  created() {
    window.node_add_modal222 = this.node_add_modal222
  },
  mounted() {},
  methods: {
    readFeedback() {
      useJwt.readFeedback({
        id: this.selected_row_id,
      })
        .then(response => {
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    updateTags() {
      if (this.tag_list != undefined) {
        const formData = new FormData()
        formData.append('feedback_id', this.selected_row_id)
        formData.append('tag_list', JSON.stringify(this.tag_list))
        useJwt.updatetFeedbackTagList(this.selected_row_id, formData)
          .then(response => {
          })
          .catch(error => {
            console.log(error.response)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Hata',
                icon: 'CheckCircleIcon',
                variant: 'danger',
                // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
              },
            })
          })
      }
    },
    getTagsOptions() {
      useJwt.getCompanyTagsList(
        {
          page_size: 5000,
        },
      )
        .then(response => {
          const temp_tag_list_options = []
          response.data.results.forEach(ttt => {
            temp_tag_list_options.push({
              title: ttt.tag,
              id: ttt.id,
            })
          })
          this.tag_list_options = temp_tag_list_options
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    getTags() {
      useJwt.getFeedbackTagList(
        {
          page_size: 5000,
          feedback: this.selected_row_id,
        },
      )
        .then(response => {
          const temp_tag_list = []
          response.data.results.forEach(ttt => {
            temp_tag_list.push({
              title: ttt.tag.tag,
              id: ttt.id,
            })
          })
          this.tag_list = temp_tag_list
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    getComments() {
      useJwt.getFeedbackCommentsList(
        {
          page_size: 5000,
          feedback: this.selected_row_id,
        },
      )
        .then(response => {
          this.comment_list = response.data.results
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    addCommentBtn() {
      if (this.comment_input_box_is_visible) {
        const payload = {
          feedback: this.selected_row_id,
          comment: this.comment_box,
        }
        useJwt.createFeedbackComments(payload)
          .then(response => {
            this.comment_box = null
            this.comment_input_box_is_visible = false
            this.getComments()
            // this.update_data_table()
          })
          .catch(error => {
            console.log(error.response)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Hata',
                icon: 'CheckCircleIcon',
                variant: 'danger',
                // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
              },
            })
          })
      } else {
        this.comment_input_box_is_visible = true
      }
    },
    update_feedback_data() {
      const formData = new FormData()
      formData.append('feedback_status', this.feedback_status.value)
      formData.append('priority_status', this.priority_status.value)
      useJwt.updateFeedback(this.selected_row_id, formData)
        .then(response => {
          this.update_data_table()
          // this.getTableData()
          // this.close_edit_record_modal()
          // this.$toast({
          //   component: ToastificationContent,
          //   position: 'top-right',
          //   props: {
          //     title: 'Kayıt Güncellendi',
          //     icon: 'CheckCircleIcon',
          //     variant: 'success',
          //     // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
          //   },
          // })
        })
        .catch(error => {
          console.log(error.response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Hata',
              icon: 'CheckCircleIcon',
              variant: 'danger',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
    },
    get_parent_name() {
      useJwt.getFeedbackFullParentNames({
        id: this.selected_row_id,
      })
        .then(response => {
          this.full_path = response.data.name_list.join(" -> ")
        })
        .catch(error => {
          console.log(error)
        })
    },
    node_add_modal222(row_id) {
      this.selected_row_id = row_id
      this.$refs.new_node_append_modal.show()
      useJwt.FeedbackDetail(this.selected_row_id)
        .then(response => {
          this.selected_node = response.data

          // Özet Verileri seçiliyor
          this.feedback_status_options.forEach(fs => {
            if (fs.value == response.data.feedback_status) {
              this.feedback_status = fs
            }
          })
          this.priority_status_options.forEach(ps => {
            if (ps.value == response.data.priority_status) {
              this.priority_status = ps
            }
          })
          // Özet Verileri seçiliyor

          // Yorumlar getiriliyor
          this.getComments()
          // Yorumlar getiriliyor

          // Taglar getiriliyor
          this.getTagsOptions()
          this.getTags()
          // Taglar getiriliyor

          // Feedback okunmadı ise okunuyor
          if (response.data.is_read == false) {
            this.readFeedback()
          }
          // Feedback okunmadı ise okunuyor

          // Feedback Full Parent Adları getiriliyor
          this.get_parent_name()
          // Feedback Full Parent Adları getiriliyor
          this.iframeSrc = `/survey_preview?view_type=survey_answer&id=${this.selected_row_id}`
        })
        .catch(error => {
          console.log(error)
        })
    },
    close_new_node_append_modal() {
      this.$refs.new_node_append_modal.hide()
      this.selected_row_id = null
      this.full_path = null
      this.selected_node = null
      this.survey = null
      this.comment_input_box_is_visible = false
      this.comment_box = null
      this.comment_list = []
    },
  },
}
</script>
<style>

</style>
