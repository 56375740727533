<template>
  <!--    SurveyGroups Modal-->
  <b-modal
      id="survey_groups_modal"
      ref="survey_groups_modal"
      centered
      no-close-on-backdrop
      content-class="shadow"
      footer-class="d-none"
      title="Anket Grupları"
      size="lg"
      @hide="close_survey_groups_modal"
  >
    <survey-group />
  </b-modal>
  <!--    SurveyGroups Modal-->
</template>
<script>
import {
  BModal, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SurveyGroup from "@/views/custom_app/client/organization_management/components/survey_groups/SurveyGroup.vue"
export default {
  components: {
    BModal,
    SurveyGroup,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
  },
  data() {
    return {
    }
  },
  watch: {},
  created() {
  },
  mounted() {},
  methods: {
    close_survey_groups_modal() {
      this.$refs.survey_groups_modal.hide()
    },
  },
}
</script>
