<template>
  <!--    Birim Taşı Modal-->
  <b-modal
    id="move_sub_node_modal"
    ref="move_sub_node_modal"
    centered
    no-close-on-backdrop
    content-class="shadow"
    footer-class="d-none"
    :title="`${selected_node.data.name} | ${$t('move_node_modal.model_title_move')}`"
    size="md"
    @hide="close_move_sub_node_modal"
  >
    <b-row>
      <!--  Taşınacak Birim Id      -->
      <b-col
        cols="12"
        class="mb-md-0 mb-2"
      >
        <b-form-group>
          <label for="name"><span class="text-danger text-bold">*</span> {{ $t('move_node_modal.lbl_move_node_id') }}</label>
          <b-input-group>
            <b-form-input
              v-model="move_sub_node_id"
              :disabled="move_sub_node_status"
              :placeholder="$t('move_node_modal.lbl_move_node_id')"
              name="name"
            />
            <b-input-group-append>
              <b-button
                :disabled="move_sub_node_status"
                variant="outline-primary"
                @click="check_move_sub_node_button"
              >
                {{ $t('move_node_modal.check_node') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <!--  Taşınacak Birim Id      -->

      <!--  Taşınacak Birim Id      -->
      <b-col
        cols="12"
        class="mb-md-0 mb-2"
      >
        <div class="text-danger text-center" v-if="move_sub_node_find==true">
          <u><b>{{ $t('move_node_modal.warning_message', { selected_node_name: selected_node.data.name, new_node_name: new_node_name }) }}</b></u>
        </div>
        <div
          v-if="move_sub_node_find==false"
          class="text-center text-danger"
        >
          <b><u>{{ $t('move_node_modal.node_not_find') }}</u></b>
        </div>
      </b-col>
      <!--  Taşınacak Birim Id      -->
      <b-col
        v-if="move_sub_node_status"
        cols="12"
        class="mb-md-0 mb-2"
      >
        <hr>
      </b-col>
      <b-col
        class="justify-content-end text-right"
      >
        <b-button
          v-if="move_sub_node_status"
          variant="primary"
          size="sm"
          :disabled="!move_sub_node_status"
          @click="move_sub_node_button"
        >
          <span class="align-middle">{{ $t('move_node_modal.btn_move') }}</span>
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
  <!--    Birim Taşı Modal-->
</template>
<script>
import {
  BButton, BCol, BFormGroup,
  BFormInput, BInputGroup, BInputGroupAppend,
  BModal, BRow, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'
import router from '@/router'

export default {
  components: {
    BFormGroup,
    BCol,
    BRow,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormInput,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    update_organization_data: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selected_node_id: null,
      selected_node: { data: { name: '' } },
      move_sub_node_find: null,
      move_sub_node_status: false,
      move_sub_node_id: null,
    }
  },
  watch: {},
  created() {
    window.node_move_modal = this.node_move_modal
  },
  mounted() {},
  methods: {
    node_move_modal(node_id) {
      this.selected_node_id = node_id
      useJwt.companyOrganizationNodesDetail(this.selected_node_id)
        .then(response => {
          this.selected_node.data = response.data
          this.$refs.move_sub_node_modal.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    move_sub_node_button() {
      const formData = new FormData()
      formData.append('parentId', this.move_sub_node_id)
      useJwt.updateCompanyOrganizationNodes(this.selected_node_id, formData)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('move_node_modal.toast_title_node_move'),
              icon: 'CheckCircleIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
          this.close_move_sub_node_modal()
          this.update_organization_data()
        })
        .catch(error => {
          console.log(error.response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'CheckCircleIcon',
              variant: 'danger',
              text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
            },
          })
        })
    },
    check_move_sub_node_button() {
      if (this.selected_node_id == this.move_sub_node_id) {
        this.move_sub_node_find = false
      } else {
        useJwt.getCompanyOrganizationNodesList({
          id: this.move_sub_node_id,
          company: getUserData().company_id,
          organization: router.currentRoute.params.id,
        })
          .then(response => {
            if (response.data.count == 1) {
              this.new_node_name = response.data.results[0].name
              this.move_sub_node_status = true
              this.move_sub_node_find = true
            } else {
              this.move_sub_node_find = false
            }
          })
          .catch(error => {
            console.log(error.response)
            this.move_sub_node_find = false
          })
      }
    },
    close_move_sub_node_modal() {
      this.selected_node_id = null
      this.new_node_name = null
      this.move_sub_node_find = null
      this.move_sub_node_id = null
      this.move_sub_node_status = false
      this.selected_node = { data: { name: '' } }
      this.$refs.move_sub_node_modal.hide()
    },
  },
}
</script>
