<template>
  <!--    qrcodeGroups Modal-->
  <b-modal
    id="qrcode_groups_modal"
    ref="qrcode_groups_modal"
    centered
    no-close-on-backdrop
    content-class="shadow"
    footer-class="d-none"
    title="QR Kod Grupları"
    size="lg"
    @hide="close_qrcode_groups_modal"
  >
    <qrcode-group />
  </b-modal>
  <!--    qrcodeGroups Modal-->
</template>
<script>
import {
  BModal, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import qrcodeGroup from '@/views/custom_app/client/organization_management/components/qrcode_groups/QrcodeGroup.vue'

export default {
  components: {
    BModal,
    qrcodeGroup,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
  },
  data() {
    return {
    }
  },
  watch: {},
  created() {
  },
  mounted() {},
  methods: {
    close_qrcode_groups_modal() {
      this.$refs.qrcode_groups_modal.hide()
    },
  },
}
</script>
