<template>
  <div>
    <dl class="row">
      <dt class="col-sm-3 text-left">
        {{ $t('general_tab.node_id') }}:
      </dt>
      <dd class="col-sm-9">
        {{ selected_node.data.id }}
      </dd>
    </dl>
    <dl class="row">
      <dt class="col-sm-3 text-left">
        {{ $t('general_tab.node_name') }}:
      </dt>
      <dd class="col-sm-9">
        {{ selected_node.data.name }}
      </dd>
    </dl>
    <dl class="row">
      <dt class="col-sm-3 text-left">
        {{ $t('general_tab.node_type') }}:
      </dt>
      <dd class="col-sm-9">
        {{ organization_type_name }}
      </dd>
    </dl>
    <dl class="row">
      <dt class="col-sm-3 text-left">
        {{ $t('general_tab.qrcode_read_count') }}:
      </dt>
      <dd class="col-sm-9">
        {{ selected_node.data.qrcode_read_count }}
      </dd>
    </dl>
    <dl class="row">
      <dt class="col-sm-3 text-left">
        {{ $t('general_tab.total_feedback_count') }}:
      </dt>
      <dd class="col-sm-9">
        {{ selected_node.data.feedback_count }}
      </dd>
    </dl>
    <dl class="row">
      <dt class="col-sm-3 text-left">
        {{ $t('general_tab.open_feedback_count') }}:
      </dt>
      <dd class="col-sm-9">
        {{ selected_node.data.open_notification_count }}
      </dd>
    </dl>
  </div>
</template>
<script>
import {
  BButton,
  BModal, BRow, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow, BButton,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    organization_node_list: {
      type: Array,
      required: true,
    },
    selected_node: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      organization_type_name: "",
    }
  },
  watch: {},
  created() {
    this.organization_node_list.forEach(organization_type => {
      if (this.selected_node.data.node_type_code == organization_type.organization_type_code){
        this.organization_type_name = organization_type.name
      }
    })
  },
  mounted() {},
  methods: {
  },
}
</script>
