<template>
  <!--    Birim Düzenle Modal-->
  <b-modal
    id="edit_sub_node_modal"
    ref="edit_sub_node_modal"
    centered
    no-close-on-backdrop
    content-class="shadow"
    footer-class="d-none"
    :title="`${$t('edit_node_modal.title_node_update')}`"
    size="md"
    @hide="close_edit_sub_node_modal"
  >
    <validation-observer
      ref="updateForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="update_sub_node_button">
        <b-row>
          <!--  Birim Türü      -->
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group>
              <label for="i-plan"><span class="text-danger text-bold">*</span> {{ $t('add_new_node_modal.node_type') }}</label>
              <v-select
                id="i-plan"
                :disabled="temp_edit_sub_node_type.organization_type_code=='main'"
                v-model="temp_edit_sub_node_type"
                :options="organization_node_list"
                :selectable="(option) => !option.organization_type_code.includes('main')"
                label="name"
                :clearable="false"
              >
                <template #option="{ name }">
                  <span> {{ name }}</span>
                </template>
                <template #selected-option="{ name }">
                  <span> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <!--  Birim Türü      -->
        </b-row>
        <b-row>
          <!--  Birim Adı      -->
          <b-col
            cols="9"
            class="mb-md-0 mb-2"
          >
            <b-form-group>
              <label for="name"><span class="text-danger text-bold">*</span> {{ $t("edit_node_modal.node_name") }}</label>
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              ><b-form-input
                 v-model="selected_node.data.name"
                 name="name"
                 :state="errors.length > 0 ? false: selected_node.data.name != null ? true : null"
               />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >{{ $t('core.required') }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--  Birim Adı      -->

          <!--  Birim Renk      -->
          <b-col
            cols="3"
            class="mb-md-0 mb-2"
          >
            <b-form-group>
              <label for="name"><span class="text-danger text-bold">*</span>{{ $t('edit_node_modal.node_color') }}</label>
              <color-picker
                v-model="selected_node.data.node_color"
                no-input
                :color="selected_node.data.node_color"
                picker="sketch"
              />
            </b-form-group>
          </b-col>
          <!--  Birim Renk      -->
        </b-row>
        <b-row
          class="justify-content-end"
        >
          <hr>
          <b-button
            variant="primary"
            size="sm"
            type="submit"
            :disabled="invalid"
          >
            <span class="align-middle">{{ $t('core.btn_update') }}</span>
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
  <!--    Birim Düzenle Modal-->
</template>
<script>
import {
  BButton, BCol, BForm, BFormGroup,
  BFormInput,
  BModal, BRow, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ColourPicker from 'vue-colour-picker'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    BForm,
    BFormGroup,
    BCol,
    BRow,
    vSelect,
    BButton,
    BFormInput,
    BModal,
    ValidationProvider,
    ValidationObserver,
    'color-picker': ColourPicker,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    organization_node_list: {
      type: Array,
      required: true,
    },
    update_organization_data: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selected_node_id: null,
      selected_node: { data: { name: '', organization_type_code: '' } },
      temp_edit_sub_node_type: null,

    }
  },
  watch: {},
  created() {
    window.node_update_modal = this.node_update_modal
  },
  mounted() {},
  methods: {
    node_update_modal(node_id) {
      this.selected_node_id = node_id
      useJwt.companyOrganizationNodesDetail(this.selected_node_id)
        .then(response => {
          this.selected_node.data = response.data
          this.organization_node_list.forEach(node => {
            if (node.organization_type_code == response.data.node_type_code) {
              this.temp_edit_sub_node_type = node
            }
          })
          this.$refs.edit_sub_node_modal.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    update_sub_node_button() {
      this.$refs.updateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('name', this.selected_node.data.name)
          formData.append('node_type_code', this.temp_edit_sub_node_type.organization_type_code)
          formData.append('node_color', this.selected_node.data.node_color)
          useJwt.updateCompanyOrganizationNodes(this.selected_node_id, formData)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('edit_node_modal.toast_title_node_updated'),
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                },
              })
              this.close_edit_sub_node_modal()
              this.update_organization_data()
            })
            .catch(error => {
              console.log(error.response)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'CheckCircleIcon',
                  variant: 'danger',
                  text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
                },
              })
            })
        }
      })
    },
    close_edit_sub_node_modal() {
      this.selected_node_id = null
      this.selected_node = { data: { name: '', organization_type_code: '' } }
      this.temp_edit_sub_node_type = {organization_type_code:""}
      this.$refs.edit_sub_node_modal.hide()
    },
  },
}
</script>
