var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"edit_sub_node_modal",attrs:{"id":"edit_sub_node_modal","centered":"","no-close-on-backdrop":"","content-class":"shadow","footer-class":"d-none","title":("" + (_vm.$t('edit_node_modal.title_node_update'))),"size":"md"},on:{"hide":_vm.close_edit_sub_node_modal}},[_c('validation-observer',{ref:"updateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.update_sub_node_button($event)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"i-plan"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('add_new_node_modal.node_type')))]),_c('v-select',{attrs:{"id":"i-plan","disabled":_vm.temp_edit_sub_node_type.organization_type_code=='main',"options":_vm.organization_node_list,"selectable":function (option) { return !option.organization_type_code.includes('main'); },"label":"name","clearable":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.temp_edit_sub_node_type),callback:function ($$v) {_vm.temp_edit_sub_node_type=$$v},expression:"temp_edit_sub_node_type"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"9"}},[_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("edit_node_modal.node_name")))]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"name","state":errors.length > 0 ? false: _vm.selected_node.data.name != null ? true : null},model:{value:(_vm.selected_node.data.name),callback:function ($$v) {_vm.$set(_vm.selected_node.data, "name", $$v)},expression:"selected_node.data.name"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"3"}},[_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('edit_node_modal.node_color')))]),_c('color-picker',{attrs:{"no-input":"","color":_vm.selected_node.data.node_color,"picker":"sketch"},model:{value:(_vm.selected_node.data.node_color),callback:function ($$v) {_vm.$set(_vm.selected_node.data, "node_color", $$v)},expression:"selected_node.data.node_color"}})],1)],1)],1),_c('b-row',{staticClass:"justify-content-end"},[_c('hr'),_c('b-button',{attrs:{"variant":"primary","size":"sm","type":"submit","disabled":invalid}},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('core.btn_update')))])])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }