<template>
  <!--    Birim Sil Modal-->
  <b-modal
    id="delete_sub_node_modal"
    ref="delete_sub_node_modal"
    centered
    no-close-on-backdrop
    content-class="shadow"
    footer-class="d-none"
    :title="`${selected_node.data.name} | ${$t('delete_node_modal.model_title_delete')}`"
    size="md"
    @hide="close_delete_sub_node_modal"
  >
    <b-row>
      <b-col
        cols="12"
        class="mb-md-0 mb-2"
      >
        <b-card-text class="text-left">
          <span class="text-danger">{{ selected_node.data.name }}</span><span> {{$t('delete_node_modal.Are_you_sure_you_want_to_delete_the_record_named')}}</span>
        </b-card-text>
        <hr>
      </b-col>
      <b-col
        cols="12"
        class="mb-md-0 mb-2"
      >
        <b-row
          class="justify-content-end"
        >
          <div><b-button
            variant="danger"
            size="sm"
            @click="delete_sub_node_button"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-1"
            />
            <span class="align-middle">{{$t('core.btn_delete')}}</span>
          </b-button></div>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
  <!--    Birim Sil Modal-->
</template>
<script>
import {
  BButton, BCardText, BCol,
  BModal, BRow, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCol,
    BRow,
    BCardText,
    BButton,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    update_organization_data: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selected_node_id: null,
      selected_node: { data: { name: '' } },
    }
  },
  watch: {},
  created() {
    window.node_delete_modal = this.node_delete_modal
  },
  mounted() {},
  methods: {
    node_delete_modal(node_id) {
      this.selected_node_id = node_id
      useJwt.companyOrganizationNodesDetail(this.selected_node_id)
        .then(response => {
          this.selected_node.data = response.data
          this.$refs.delete_sub_node_modal.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    delete_sub_node_button() {
      useJwt.companyOrganizationNodesDelete(this.selected_node_id)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.record_deleted'),
              icon: 'TrashIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
          this.close_delete_sub_node_modal()
          this.update_organization_data()
        })
        .catch(error => {
          console.log(error.response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'CheckCircleIcon',
              variant: 'danger',
              text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
            },
          })
        })
    },
    close_delete_sub_node_modal() {
      this.selected_node_id = null
      this.selected_node = { data: { name: '' } }
      this.$refs.delete_sub_node_modal.hide()
    },
  },
}
</script>
