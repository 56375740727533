<template>
  <!--    Birim Ekle Modal-->
  <b-modal
    id="add_sub_node_modal"
    ref="add_sub_node_modal"
    centered
    no-close-on-backdrop
    content-class="shadow"
    footer-class="d-none"
    :title="`${selected_node.data.name} | ${$t('add_new_node_modal.title_node_add')}`"
    size="lg"
    @hide="close_add_sub_node_modal"
  >
    <validation-observer
      ref="addForm"
      #default="{invalid}"
    >

      <b-form
          @submit.prevent="add_sub_node_button" >
        <div
            style="min-height: 600px!important;"
        >
          <b-row>
            <!--  Birim Türü      -->
            <b-col cols="8">
              <b-form-group>
                <label for="i-plan"><span class="text-danger text-bold">*</span> {{ $t('add_new_node_modal.node_type') }}</label>
                <validation-provider
                    #default="{ errors }"
                    name="i-plan"
                    rules="required"
                >
                  <v-select
                      id="i-plan"
                      v-model="temp_sub_node_type"
                      :state="errors.length > 0 ? false: temp_sub_node_type.organization_type_code == null ? true : null"
                      :options="organization_node_list"
                      :selectable="(option) => !option.organization_type_code.includes('main')"
                      label="name"
                      :clearable="false"
                  >
                    <template #option="{ name }">
                      <span> {{ name }}</span>
                    </template>
                    <template #selected-option="{ name }">
                      <span> {{ name }}</span>
                    </template>
                  </v-select>
                  <small
                      v-if="errors[0]"
                      class="text-danger"
                  >{{ $t('core.required') }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--  Birim Türü      -->

            <!--  Birim Sayısı      -->
            <b-col
                v-show="temp_sub_node_type.organization_type_code != null"
                cols="4"
            >
              <b-form-group>
                <label for="sb-maxmin"><span class="text-danger text-bold">*</span> {{ $t('add_new_node_modal.node_count') }}</label>
                <validation-provider
                    #default="{ errors }"
                    name="sb-maxmin"
                    rules="required"
                >
                  <b-form-spinbutton
                      id="sb-maxmin"
                      v-model="node_count"
                      min="1"
                      max="100"
                      :state="errors.length > 0 ? false: node_count > 0 ? true : false"
                      @change="onSpinbuttonChange"
                  />
                  <small
                      v-if="errors[0]"
                      class="text-danger"
                  >{{ $t('core.required') }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--  Birim Sayısı      -->
          </b-row>
          <div
              style="overflow-y: auto; height:500px;"
          >
            <b-row
                v-for="(node, index) in new_node_list"
                :key="index"
            >
              <!--  Birim Adı      -->
              <b-col
                  cols="7"
                  class="mb-md-0 mb-2"
              >
                <b-form-group>
                  <label for="name"><span class="text-danger text-bold">*</span> {{ index +1 }}. {{ temp_sub_node_type.name }}</label>
                  <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                  ><b-form-input
                      v-model="node['node_name']"
                      name="name"
                      :state="errors.length > 0 ? false: node['node_name'] != null ? true : null"
                      :placeholder="`${index+1} ${temp_sub_node_type.name}`"
                  />
                    <small
                        v-if="errors[0]"
                        class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--  Birim Adı      -->

              <!--  Birim Renk      -->
              <b-col
                  cols="5"
                  class="mb-md-0 mb-2"
              >
                <b-form-group>
                  <label for="name"><span class="text-danger text-bold">*</span>{{ $t('add_new_node_modal.node_color') }}</label>
                  <color-picker
                      v-model="node['node_color']"
                      no-input
                      :color="node['node_color']"
                      picker="sketch"
                  />
                </b-form-group>
              </b-col>
              <!--  Birim Renk      -->
            </b-row>
          </div>
          <b-row>
            <b-col class="justify-content-end text-right">
              <hr>
              <b-button
                  variant="primary"
                  size="sm"
                  type="submit"
                  :disabled="invalid"
              >
                <span class="align-middle">{{ $t('core.btn_add') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
  <!--    Birim Ekle Modal-->
</template>
<script>
import {
  BButton, BCol, BForm, BFormGroup,
  BFormInput, BFormSpinbutton, BModal, BNavItem, BRow,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ColourPicker from 'vue-colour-picker'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    BForm,
    BNavItem,
    BFormGroup,
    BCol,
    BRow,
    vSelect,
    BButton,
    BModal,
    BFormInput,
    BFormSpinbutton,
    ValidationProvider,
    ValidationObserver,
    'color-picker': ColourPicker,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    organization_node_list: {
      type: Array,
      required: true,
    },
    update_organization_data: {
      type: Function,
      required: true,
    },
    get_user_node_access_list: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selected_node_id: null,
      selected_node: { data: { name: '' } },
      node_count: 0,
      node_count_history: 0,
      new_node_list: [],
      temp_sub_node_type: { name: this.$t('add_new_node_modal.select_node_type'), organization_type_code: null },
    }
  },
  watch: {},
  created() {
    window.node_add_modal = this.node_add_modal
  },
  mounted() {},
  methods: {
    onSpinbuttonChange(newValue) {
      if (newValue > this.node_count_history) {
        this.new_node_list.push({ node_name: null, node_color: this.temp_sub_node_type.default_colour })
      } else if (this.new_node_list.length > 1) {
        this.new_node_list.pop()
      }
      this.node_count_history = newValue
    },
    add_sub_node_button() {
      this.$refs.addForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('company', getUserData().company_id)
          formData.append('organization', router.currentRoute.params.id)
          formData.append('parentId', this.selected_node_id)
          formData.append('node_type_code', this.temp_sub_node_type.organization_type_code)
          formData.append('node_list', JSON.stringify(this.new_node_list))
          useJwt.createCompanyOrganizationNodes(formData)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('add_new_node_modal.toast_title_node_add'),
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                },
              })
              this.close_add_sub_node_modal()
              this.get_user_node_access_list()
              this.update_organization_data()
            })
            .catch(error => {
              console.log(error.response)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'CheckCircleIcon',
                  variant: 'danger',
                  text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
                },
              })
            })
        }
      })
    },
    close_add_sub_node_modal() {
      this.selected_node_id = null
      this.selected_node = { data: { name: '' } }
      this.temp_sub_node_type = null
      this.node_count = 0
      this.node_count_history = 0
      this.new_node_list = []
      this.temp_sub_node_type = { name: this.$t('add_new_node_modal.select_node_type'), organization_type_code: null }
      this.$refs.add_sub_node_modal.hide()
    },
    node_add_modal(node_id) {
      this.selected_node_id = node_id
      useJwt.companyOrganizationNodesDetail(this.selected_node_id)
        .then(response => {
          this.selected_node.data = response.data
          this.$refs.add_sub_node_modal.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },

  },
}
</script>
