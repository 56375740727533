<template>
  <div>
    <b-row v-if="qrcode_is_setup==false">
      <b-col cols="12">
        <b-alert
        variant="warning"
        show
      >
        <h4 class="alert-heading">
          QR Kod tasarımı yapılmamış!
        </h4>
        <div class="alert-body">
          <span>Aşağıdaki <b>Tasarıma Başla</b> butonuna tıklayarak kurulum yapabilirsiniz.</span>
        </div>
      </b-alert>
      </b-col>
      <b-col
        class="justify-content-around"
        cols="12"
      >
        <b-row class="justify-content-around">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="setup_qrcode_design_button()"
          >
            <span class="align-middle">Tasarıma Başla</span>
            <feather-icon
              icon="ArrowRightIcon"
              class="ml-50"
            />
          </b-button>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="qrcode_is_setup==true">
      <b-col cols="12">
        <b-row class="justify-content-end">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              size="sm"
              @click="setup_qrcode_design_button()"
          >
            <span class="align-middle">Tasarımı Düzenle</span>
            <feather-icon
                icon="ArrowRightIcon"
                class="ml-50"
            />
          </b-button>
        </b-row>
        <hr>
      </b-col>
      <b-col class="d-flex justify-content-center" cols="12">
        <img
            style="max-height: 500px"
            class="border border-warning" v-if="previewImage"  :src="previewImage" alt="Canvas Preview" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BAlert,
  BButton, BButtonGroup, BCol, BDropdown, BDropdownDivider, BDropdownItem,
  BModal, BRow, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {fabric} from "fabric";

export default {
  components: {
    BCol,
    BRow,
    BAlert,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    selected_row_data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      previewImage: null,
      canvas: null,
      qrcode_is_setup: false,
    }
  },
  watch: {},
  created() {
    if (this.selected_row_data.qrcode_template_json !== null) {
      this.qrcode_is_setup = true
      this.canvas = new fabric.Canvas('', {
        width: this.selected_row_data.qrcode_template_json.clipPath.width,
        height: this.selected_row_data.qrcode_template_json.clipPath.height,
        backgroundColor: this.selected_row_data.qrcode_template_json.clipPath.fill,
      })
      this.canvas.loadFromJSON(this.selected_row_data.qrcode_template_json, (ee => {
        this.canvas.renderAll.bind(this.canvas)
        this.previewImage = this.canvas.toDataURL()
      }))
    }
  },
  mounted() {},
  methods: {
    setup_qrcode_design_button() {
      this.$router.push({ name: 'qrcode_management', query: { id: this.selected_row_data.id,qrcode_type: 'group' } })
    },
  },
}
</script>
