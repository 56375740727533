<template>
  <div>
    <feedback-list-table view_mode="node"  :selected_node="selected_node"/>
  </div>
</template>

<script>

import {
  BBadge,
  BButton, BButtonGroup,
  BCol,
  BCard,
  BDropdown,
  BDropdownItem, BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";
import FeedbackListTable from "@/views/custom_app/client/feedback_management/components/FeedbackListTable.vue"
export default {
  components: {
    BFormGroup,
    BFormInput, BPagination, vSelect, BButton, BTable, BRow, BCol, BDropdown, BBadge, BDropdownItem,
    BTab, BTabs,
    BCard,
    BButtonGroup,
    FeedbackListTable,
  },
  props: {
    selected_node: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
  },
}
</script>
<style lang="scss">
</style>