<template>
  <div>
    <qr-code-download-helper :selected_node="selected_node" />
    <!--    Gruba Ekle Modal-->
    <b-modal
      id="insert_qrcode_group"
      ref="insert_qrcode_group"
      centered
      no-close-on-backdrop
      content-class="shadow"
      :title="$t('qrcode_tab.btn_insert_qrcode_group')"
      footer-class="d-none"
      size="md"
      @hide="close_insert_qrcode_group"
    >
      <div>
        <validation-observer
          ref="insertQrcodeGroupForm"
          #default="{invalid}"
        >
          <b-form @submit.prevent="insert_qrcode_group_button">
            <b-row>
              <b-col
                cols="12"
                class="mb-md-0 mb-2"
              >
                <b-form-group>
                  <label for="name"><span class="text-danger text-bold">*</span> {{ $t('qrcode_tab.qrcode_group') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="qrcode_group"
                    rules="required"
                  >
                    <v-select
                      id="qrcode_group"
                      v-model="qrcode_group"
                      :options="qrcode_group_options"
                      :state="errors.length > 0 ? false: qrcode_group != null ? true : null"
                      label="group_name"
                      :clearable="false"
                      class="mx-25"
                      :placeholder="$t('qrcode_tab.qrcode_group')"
                    >
                      <template #option="{ title, icon }" />
                    </v-select>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row class="justify-content-end">
              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                type="submit"
                :disabled="invalid"
              >
                <span class="align-middle">{{ $t('survey_tab.include_in_group') }}</span>
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </div>

    </b-modal>
    <!--    Gruba Ekle Modal-->
    <!--    Farklı Gruba Taşı Modal-->
    <b-modal
      id="move_qrcode_group"
      ref="move_qrcode_group"
      centered
      no-close-on-backdrop
      content-class="shadow"
      :title="$t('qrcode_tab.btn_move_qrcode_group')"
      footer-class="d-none"
      size="md"
      @hide="close_move_qrcode_group"
    >
      <div>
        <validation-observer
          ref="moveQrcodeGroupForm"
          #default="{invalid}"
        >
          <b-form @submit.prevent="move_qrcode_group_button">
            <b-row>
              <b-col
                cols="12"
                class="mb-md-0 mb-2"
              >
                <b-form-group>
                  <label for="name"><span class="text-danger text-bold">*</span> {{ $t('qrcode_tab.qrcode_group') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="qrcode_group"
                    rules="required"
                  >
                    <v-select
                      id="qrcode_group"
                      v-model="move_qrcode_group"
                      :options="qrcode_group_options"
                      :state="errors.length > 0 ? false: move_qrcode_group != null ? true : null"
                      label="group_name"
                      :clearable="false"
                      class="mx-25"
                      :placeholder="$t('qrcode_tab.qrcode_group')"
                    >
                      <template #option="{ title, icon }" />
                    </v-select>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row class="justify-content-end">
              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                type="submit"
                :disabled="invalid"
              >
                <span class="align-middle">{{ $t('qrcode_tab.btn_move_qrcode_group') }}</span>
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </div>

    </b-modal>
    <!--    Farklı Gruba Taşı Modal-->
    <!--    Özel Tasarıma Çevir Modal-->
    <b-modal
      id="convert_qrcode_group_to_custom_design"
      ref="convert_qrcode_group_to_custom_design"
      centered
      no-close-on-backdrop
      content-class="shadow"
      :title="$t('survey_tab.btn_custom_design')"
      footer-class="d-none"
      size="md"
      variant="warning"
      @hide="close_convert_qrcode_group_to_custom_design"
    >
      <div>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <h6>{{ $t('survey_tab.custom_design_warning_title') }}</h6>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-between mx-1">
          <b-button
            size="sm"
            variant="secondary"
            @click="close_convert_qrcode_group_to_custom_design"
          >
            <span class="align-middle">{{ $t('survey_tab.btn_custom_design_cancel') }}</span>
          </b-button>
          <b-button
            size="sm"
            variant="warning"
            @click="btn_custom_design_ok"
          >
            <span class="align-middle">{{ $t('survey_tab.btn_custom_design_ok') }}</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Özel Tasarıma Çevir Modal-->
    <b-row v-if="selected_node.data.qrcode_is_setup==false">
      <b-col cols="12">
        <b-alert
          variant="warning"
          show
        >
          <h4 class="alert-heading">
            {{ $t('qrcode_tab.warning_title') }}
          </h4>
          <div class="alert-body">
            <span>{{ $t('qrcode_tab.warning_message') }}</span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        class="justify-content-around"
        cols="12"
      >
        <b-row class="justify-content-around">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="setup_qrcode_node_button()"
          >
            <span class="align-middle">{{ $t('qrcode_tab.btn_custom_design') }}</span>
            <feather-icon
              icon="ArrowRightIcon"
              class="ml-50"
            />
          </b-button>
          <b-button
            v-b-modal.insert_qrcode_group
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
          >
            <span class="align-middle">{{ $t('qrcode_tab.btn_insert_qrcode_group') }}</span>
            <feather-icon
              icon="ArrowRightIcon"
              class="ml-50"
            />
          </b-button>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="selected_node.data.qrcode_is_setup==true">
      <b-col cols="12">
        <b-row class="justify-content-end">
          <b-button
              v-b-modal.qrcode_download_helper
              size="sm"
              variant="primary"
              class="mr-1"
          >
            <span class="align-middle">{{ $t('qrcode_tab.btn_qrcode_design_download') }} <i class="pi pi-download" /></span>
          </b-button>
          <b-button-group>
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              size="sm"
              variant="outline-primary"
              :text="$t('qrcode_tab.btn_edit')"
            >
              <b-dropdown-item
                v-if="selected_node.data.qrcode_type =='one'"
                @click="$router.push({ name: 'qrcode_management', query: { id: temp_qrcode_data.id, qrcode_type: 'one' } })"
              >
                <span
                  size="sm"
                  class="align-middle"
                >{{ $t("qrcode_tab.btn_qrcode_edit") }} </span><feather-icon
                  icon="ArrowRightIcon"
                  class="ml-50"
                />
              </b-dropdown-item>
              <b-dropdown-item
                v-if="selected_node.data.qrcode_type =='one'"
                v-b-modal.insert_qrcode_group
              >
                <span
                  size="sm"
                  class="align-middle"
                >{{ $t("qrcode_tab.btn_insert_qrcode_group") }}</span><feather-icon
                  icon="ArrowRightIcon"
                  class="ml-50"
                />
              </b-dropdown-item>
              <!--                      <b-dropdown-item v-if="selected_node.data.qrcode_type =='one'"><span size="sm" class="align-middle">Yeni Anket Grubu Oluştur</span><feather-icon icon="ArrowRightIcon" class="ml-50"/></b-dropdown-item>-->

              <b-dropdown-item
                  @click="$router.push({ name: 'qrcode_management', query: { id: temp_qrcode_data.id, qrcode_type: 'group' } })"
                  v-if="selected_node.data.qrcode_type =='group'">
                <span
                  size="sm"
                  class="align-middle"
                >{{ $t("qrcode_tab.btn_qrcode_group_design_edit") }}</span><feather-icon
                  icon="ArrowRightIcon"
                  class="ml-50"
                />
              </b-dropdown-item>
              <b-dropdown-item
                v-if="selected_node.data.qrcode_type =='group'"
                v-b-modal.move_qrcode_group
              >
                <span
                  size="sm"
                  class="align-middle"
                >{{ $t("qrcode_tab.btn_insert_other_qrcode_group") }}</span><feather-icon
                  icon="ArrowRightIcon"
                  class="ml-50"
                />
              </b-dropdown-item>
              <b-dropdown-item v-b-modal.convert_qrcode_group_to_custom_design v-if="selected_node.data.qrcode_type =='group'">
                <span
                  size="sm"
                  class="align-middle"
                >{{ $t("qrcode_tab.btn_custom_design") }}</span><feather-icon
                  icon="ArrowRightIcon"
                  class="ml-50"
                />
              </b-dropdown-item>
            </b-dropdown>
          </b-button-group>
        </b-row>
        <hr>
      </b-col>
      <b-col
        cols="12"
        class="d-flex justify-content-center"
      >
        <b-alert
          v-if="!previewImage"
          variant="secondary"
          show
        >
          <div class="alert-body">
            {{$t('qrcode_tab.qrcode_preview_stating')}}
          </div>
        </b-alert>

        <img
          v-if="previewImage"
          class="border border-dark "
          style="max-height: 500px"
          :src="previewImage"
          alt="Canvas Preview"
        >

      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BAlert,
  BButton, BButtonGroup, BCol, BDropdown, BDropdownDivider, BDropdownItem, BForm, BFormGroup,
  BModal, BRow, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { fabric } from 'fabric'
import QrCodeStyling from 'qr-code-styling'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import QrCodeDownloadHelper
  from "@/views/custom_app/client/organization_management/components/detail_node_modal/QrCodeDownloadHelper.vue";
export default {
  components: {
    BForm,
    ValidationProvider,
    vSelect,
    BFormGroup,
    BCol,
    BRow,
    BDropdown,
    BDropdownDivider,
    BAlert,
    BDropdownItem,
    BButton,
    BButtonGroup,
    BModal,
    ValidationObserver,
    QrCodeDownloadHelper,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    selected_node: {
      type: Object,
      required: true,
    },
    selected_node_id: {
      type: String,
      required: true,
    },
    close_view_record_modal_selected_noda: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      previewImage: null,
      canvas: null,
      qrcode: null,
      base64: null,
      temp_qrcode_data: null,
      qrcode_group: null,
      move_qrcode_group: null,
      qrcode_group_options: null,
    }
  },
  watch: {},
  created() {
    this.check_qrcode_status()
    this.getQrCodeGroupData()
  },
  methods: {
    async getQRCodeDataURL(value, qrcode_meta) {
      const path = require('@/assets/images/logo/geribildir.io.png')
      const qrCode = new QrCodeStyling({
        width: qrcode_meta.width,
        height: qrcode_meta.height,
        data: value,
        image: path,
        qrOptions: {
          errorCorrectionLevel: qrcode_meta.extension.errorCorrectionLevel,
        },
        dotsOptions: {
          color: qrcode_meta.extension.dotsColor,
          type: qrcode_meta.extension.dotsType,
        },
        cornersSquareOptions: {
          color: qrcode_meta.extension.cornersSquareColor,
          type: qrcode_meta.extension.cornersSquareType,
        },
        cornersDotOptions: {
          color: qrcode_meta.extension.cornersDotColor,
          type: qrcode_meta.extension.cornersDotType,
        },
        backgroundOptions: {
          color: qrcode_meta.extension.background,
        },
        imageOptions: {
          margin: 0,
        },
      })
      const ss = await qrCode.getRawData('png').then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })).catch(error => {
        console.error('Error:', error)
      })
      return ss
    },
    check_qrcode_status() {
      this.qrcode = null
      if (this.selected_node.data.qrcode_is_setup === true) {
        if (this.selected_node.data.qrcode_type === 'one') {
          useJwt.getNodeQRCodeList({ node: this.selected_node_id })
            .then(async response => {
              // eslint-disable-next-line prefer-destructuring
              this.temp_qrcode_data = response.data.results[0]
              for (const obj of this.temp_qrcode_data.qrcode_template_json.objects) {
                if (obj.extensionType && obj.extensionType == 'qrcode') {
                  const qr_code_url = `${process.env.VUE_APP_PORTAL_URL}node_query?url_token=${this.selected_node.data.url_token}`
                  const mmm = await this.getQRCodeDataURL(qr_code_url, obj)
                  obj.src = mmm
                }
              }
              this.canvas_render()
            })
            .catch(error => {
              console.log(error)
            })
        }
        if (this.selected_node.data.qrcode_type === 'group') {
          useJwt.getQRCodeGroupNodesList({ node: this.selected_node_id })
            .then(async response => {
              // eslint-disable-next-line prefer-destructuring
              this.temp_qrcode_data = response.data.results[0].group
              for (const obj of this.temp_qrcode_data.qrcode_template_json.objects) {
                if (obj.extensionType && obj.extensionType == 'qrcode') {
                  const qr_code_url = `${process.env.VUE_APP_PORTAL_URL}node_query?url_token=${this.selected_node.data.url_token}`
                  const mmm = await this.getQRCodeDataURL(qr_code_url, obj)
                  obj.src = mmm
                }
              }
              this.canvas_render()
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    },
    canvas_render() {
      this.canvas = new fabric.Canvas('', {
        width: this.temp_qrcode_data.qrcode_template_json.clipPath.width,
        height: this.temp_qrcode_data.qrcode_template_json.clipPath.height,
        backgroundColor: this.temp_qrcode_data.qrcode_template_json.clipPath.fill,
      })
      this.canvas.loadFromJSON(this.temp_qrcode_data.qrcode_template_json, (ee => {
        this.canvas.renderAll.bind(this.canvas)
        this.previewImage = this.canvas.toDataURL()
      }))
    },
    setup_qrcode_node_button() {
      useJwt.setupQRCodeCompanyOrganizationNodes(this.selected_node_id)
        .then(response => {
          this.close_view_record_modal_selected_noda()
          this.$router.push({ name: 'qrcode_management', query: { id: response.data.qrcode_id, qrcode_type: 'one' } })
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getQrCodeGroupData() {
      useJwt.getQRCodeGroupsList({
        company: getUserData().company_id,
        organization: this.selected_node.data.organization,
        page_size: 5000,
      })
        .then(response => {
          this.qrcode_group_options = response.data.results
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    selected_node_update() {
      useJwt.companyOrganizationNodesDetail(this.selected_node.data.id)
        .then(response => {
          this.selected_node.data = response.data
          this.check_qrcode_status()
        })
    },
    insert_qrcode_group_button() {
      this.$refs.insertQrcodeGroupForm.validate().then(success => {
        if (success) {
          const node_list = []
          node_list.push(this.selected_node.data.id)
          const formData = new FormData()
          formData.append('group', this.qrcode_group.id)
          formData.append('company', getUserData().company_id)
          formData.append('organization', this.selected_node.data.organization)
          formData.append('node_list', node_list)
          // formData.append('node_list', [1,2,3])
          useJwt.QRCodeGroupNodesmultiAddSurveyGroups(formData)
            .then(response => {
              this.selected_node_update()
              this.close_insert_qrcode_group()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('qrcode_tab.qrcode_insert_to_group'),
                  icon: 'SaveIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'AlertIcon',
                  variant: 'danger',
                  text: error.response.data.detail,
                },
              })
              console.log(error.response)
            })
        }
      })
    },
    move_qrcode_group_button() {
      this.$refs.moveQrcodeGroupForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('group', this.move_qrcode_group.id)
          formData.append('node', this.selected_node.data.id)
          // formData.append('node_list', [1,2,3])
          useJwt.QRCodeGroupNodesnodeMoveToOtherGroup(formData)
            .then(response => {
              this.selected_node_update()
              this.close_move_qrcode_group()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('qrcode_tab.qrcode_group_changed'),
                  icon: 'SaveIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'AlertIcon',
                  variant: 'danger',
                  text: error.response.data.detail,
                },
              })
              console.log(error.response)
            })
        }
      })
    },
    btn_custom_design_ok() {
      const formData = new FormData()
      formData.append('node', this.selected_node.data.id)
      useJwt.QRCodeGroupNodesconvertGroupToOneDesign(formData)
        .then(response => {
          this.$router.push({ name: 'qrcode_management', query: { id: response.data.qrcode_id, qrcode_type: 'one' } })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertIcon',
              variant: 'danger',
              text: error.response.data.detail,
            },
          })
          console.log(error.response)
        })
    },
    close_insert_qrcode_group() {
      this.$refs.insert_qrcode_group.hide()
      this.qrcode_group = null
    },
    close_move_qrcode_group() {
      this.$refs.move_qrcode_group.hide()
      this.move_qrcode_group = null
    },
    close_convert_qrcode_group_to_custom_design() {
      this.$refs.convert_qrcode_group_to_custom_design.hide()
    },
  },
}
</script>
